import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Content from "./components/Content";
import contentApi from "./api/content";
import storage from "../../../../../utils/storage";

export default function Index() {
  const [content, setContent] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [personaCategoryFilter, setPersonaCategoryFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const { websiteID, dateRange, selectedPersonasFilters } = useSelector(
    (state) => state.generalData
  );
  const pmsToggleActivation = JSON.parse(storage.getItem("isToggled"));
  const [longtailKeywordFilter, setLongtailKeywordFilter] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    const categoryFilter =
      selectedPersonasFilters?.[t("personaDashboard.categories")]?.[0] || null;

    setPersonaCategoryFilter(categoryFilter);
    const longtails =
      selectedPersonasFilters?.[t("analyticsPage.longTailKeywords")] || null;
    setLongtailKeywordFilter(longtails);
  }, [selectedPersonasFilters, t]);

  const apiHandle = async () => {
    try {
      setLoading(true);
      const res = await contentApi({
        website_id: websiteID,
        dateRange: [dateRange],
        // filter: personaCategoryFilter,
        pageNumber,
        pmsData: pmsToggleActivation,
        longtailKeywordFilter
      });
      if (res.data.status === 200) {
        setContent(res.data.result[0].result);
        setPageCount(res.data.result[0].pageCount);
        setLoading(false);
      }
    } catch (error) {
      // handle error
      setLoading(false);
    }
  };

  useEffect(() => {
    if (personaCategoryFilter || longtailKeywordFilter?.length > 0) {
      apiHandle();
    }
    if (personaCategoryFilter === null || longtailKeywordFilter?.length === 0) {
      setContent([]);
    }
  }, [personaCategoryFilter, longtailKeywordFilter, dateRange, pageNumber]);

  const handlePagination = (event, page) => {
    setPageNumber(page);
  };

  return (
    <Content
      content={content}
      pageCount={pageCount}
      pageNumber={pageNumber}
      handlePagination={handlePagination}
      loading={loading}
    />
  );
}
