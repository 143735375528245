import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  insights: {
    requests: 0,
    bookings: 0,
    revenue: 0,
    conversionRate: 0,
    revenuePerBooking: 0
  },
  visitorsLineData: {},
  personaData: {},
  typeData: {},
  roomsData: {},
  channelsData: {},
  daysOfStayData: {},
  guestType: {},
  bookingGroup: {},
  adPartnerData: {},
  adMediumData: {},
  adCampaignData: {},
  monthlyRequestData: {},
  monthlyBookingData: {},
  countriesData: {},
  ageData: {},
  spendData: {},
  genderData: {},
  avgPriceData: {},
  isLoading: true
};

const comparePMSSlice = createSlice({
  name: "comparePMSData",
  initialState,
  reducers: {
    setCompareIsLoading: (state) => {
      return { ...state, isLoading: !state.isLoading };
    },
    setCompareInsights: (state, actions) => {
      return { ...state, insights: { ...actions.payload } };
    },
    setCompareVisitorsLineData: (state, actions) => {
      return { ...state, visitorsLineData: { ...actions.payload } };
    },
    setComparePersonaData: (state, actions) => {
      return { ...state, personaData: { ...actions.payload } };
    },
    setCompareTypeData: (state, actions) => {
      return { ...state, typeData: { ...actions.payload } };
    },
    setCompareRoomsData: (state, actions) => {
      return { ...state, roomsData: { ...actions.payload } };
    },
    setCompareChannelsData: (state, actions) => {
      return { ...state, channelsData: { ...actions.payload } };
    },
    setCompareDaysOfStayData: (state, actions) => {
      return { ...state, daysOfStayData: { ...actions.payload } };
    },
    setCompareBookingGroup: (state, actions) => {
      return { ...state, bookingGroup: { ...actions.payload } };
    },
    setCompareGuestType: (state, actions) => {
      return { ...state, guestType: { ...actions.payload } };
    },
    setCompareAdMediumData: (state, actions) => {
      return { ...state, adMediumData: { ...actions.payload } };
    },
    setCompareAdPartnerData: (state, actions) => {
      return { ...state, adPartnerData: { ...actions.payload } };
    },
    setCompareAdCampaignData: (state, actions) => {
      return { ...state, adCampaignData: { ...actions.payload } };
    },
    setCompareMonthlyRequestData: (state, actions) => {
      return { ...state, monthlyRequestData: { ...actions.payload } };
    },
    setCompareMonthlyBookingData: (state, actions) => {
      return { ...state, monthlyBookingData: { ...actions.payload } };
    },
    setCompareCountriesData: (state, actions) => {
      return { ...state, countriesData: { ...actions.payload } };
    },
    setCompareAgeData: (state, actions) => {
      return { ...state, ageData: { ...actions.payload } };
    },
    setCompareSpendData: (state, actions) => {
      return { ...state, spendData: { ...actions.payload } };
    },
    setCompareGenderData: (state, actions) => {
      return { ...state, genderData: { ...actions.payload } };
    },
    setCompareAvgPriceData: (state, actions) => {
      return { ...state, avgPriceData: { ...actions.payload } };
    }
  }
});

export const {
  setCompareIsLoading,
  setCompareInsights,
  setCompareVisitorsLineData,
  setComparePersonaData,
  setCompareRoomsData,
  setCompareChannelsData,
  setCompareDaysOfStayData,
  setCompareBookingGroup,
  setCompareGuestType,
  setCompareMonthlyRequestData,
  setCompareMonthlyBookingData,
  setCompareCountriesData,
  setCompareAgeData,
  setCompareSpendData,
  setCompareGenderData,
  setCompareAvgPriceData,
  setCompareTypeData,
  setCompareAdCampaignData,
  setCompareAdMediumData,
  setCompareAdPartnerData
} = comparePMSSlice.actions;

export default comparePMSSlice.reducer;
