/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import Selectchannel from "../../../../../assets/icons/Selectchannel.png";
import themeColors from "../../../../../assets/stylesheets/_var.scss";
import { ButtonDark } from "../../../../../components/inputElements/buttons/MainButton";
import "../assets/stylesheets/selectChannel.scss";
import { useDispatch } from "react-redux";

export default function SelectChannel({
  isHoveredChannel,
  handleMouseEnterChannel,
  handleMouseLeaveChannel,
  campaignDetails,
  loading,
  handleGetCampaignDetails,
  handleSelectChannel,
  selectedChannels
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div
      className={`select-types ${
        isHoveredChannel || campaignDetails?.length > 0 ? "isHovered" : ""
      }`}
      onMouseEnter={handleMouseEnterChannel}
      onMouseLeave={handleMouseLeaveChannel}
    >
      <div className="step-text">{t("manageCampaign.step")} 1</div>
      <div className="select-types-text">
        {t("manageCampaign.selectChannel")}
      </div>

      {loading ? (
        <div className="flex-center">
          <TailSpin
            height="50"
            width="50"
            color={themeColors.primaryColor}
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="spinner"
            visible
          />
        </div>
      ) : campaignDetails?.length == 0 ? (
        <div className="margins-no-selections">
          <ButtonDark
            buttonText={t("manageCampaign.letsStart")}
            onClick={() => {
              handleGetCampaignDetails();
            }}
          />
        </div>
      ) : (
        campaignDetails?.map((channel, index) => {
          const isSelected = selectedChannels.some(
            (ch) => ch.channel_id === channel.channel_id
          );
          return (
            <div className="margins-selections">
              <div
                className={`flex-center-align selected-background ${
                  isSelected ? "selected" : ""
                }`}
                onClick={() => {
                  handleSelectChannel(channel);
                }}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  // Handling keyboard events
                  if (e.key === "Enter" || e.key === " ") {
                    handleSelectChannel(channel);
                  }
                }}
                key={channel?.channel_id}
              >
                <div className="icon-div">
                  <img className="icon" src={channel?.channel_image} />
                </div>
                <div>
                  <div className={`type-name ${isSelected ? "selected" : ""}`}>
                    {channel?.channelName}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}

      {campaignDetails?.length == 0 && (
        <div className="flex-center">
          <img src={Selectchannel} />
        </div>
      )}
    </div>
  );
}
