import "../assets/stylesheets/barGraph.scss";
import PropTypes from "prop-types";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip
} from "chart.js";
import { Bar, getElementsAtEvent } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  updateSelectedFilters,
  updateSelectedPMSFilters,
  updateSelectedPersonasFilters,
  updateSelectedISIFilters,
  updateSelectedCampPerfomanceFilters,
  updateSelectedEcommerceFilters
} from "../../../../store/slices/generalSlice";
import themeColors from "../../../../assets/stylesheets/_var.scss";
import { updateSelectedRange } from "../../../../store/slices/isiChatDataSlice";
import {
  incrementActions,
  updateComponent
} from "../../../../store/slices/trackingSlice";

export default function BarGraph({
  options,
  data,
  dashboard,
  filterValues,
  isCompare,
  selectedRange
}) {
  const chartRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleUpdateFilters = (label, clickedLabel, event) => {
    let currentFilters = filterValues[label] || [];
    const updatedFilters = { ...filterValues };

    if (currentFilters.includes(clickedLabel)) {
      currentFilters = currentFilters.filter((item) => item !== clickedLabel);
      if (currentFilters.length > 0) {
        updatedFilters[label] = currentFilters;
      } else {
        delete updatedFilters[label];
      }
    } else {
      updatedFilters[label] =
        event.metaKey || event.ctrlKey
          ? [...currentFilters, clickedLabel]
          : [clickedLabel];
    }

    return updatedFilters;
  };

  const onClick = (event) => {
    console.log("page clicked");
    const clicked = getElementsAtEvent(chartRef.current, event);
    if (clicked.length === 0) return;

    const { label } = data.datasets[0];
    const clickedLabel = data.labels[clicked[0].index];
    let updatedFilters;

    switch (dashboard) {
      case "analytics":
        if (label === t("analyticsPage.countryTitle")) {
          updatedFilters = handleUpdateFilters(
            t("analyticsPage.countryTitle"),
            clickedLabel,
            event
          );
          updatedFilters[t("analyticsPage.worldTitle")] =
            updatedFilters[t("analyticsPage.countryTitle")] || [];
          dispatch(updateSelectedFilters(updatedFilters));

          console.log("label", label, "clickedLabel", clickedLabel);
          dispatch(
            updateComponent({
              component: label,
              component_label: clickedLabel
            })
          );
          dispatch(incrementActions());
        } else {
          updatedFilters = handleUpdateFilters(label, clickedLabel, event);
          dispatch(
            updateComponent({
              component: label,
              component_label: clickedLabel
            })
          );
          dispatch(incrementActions());
          dispatch(updateSelectedFilters(updatedFilters));
        }
        break;
      case "pms":
        updatedFilters = handleUpdateFilters(label, clickedLabel, event);
        dispatch(updateSelectedPMSFilters(updatedFilters));
        dispatch(
          updateComponent({
            component: label,
            component_label: clickedLabel
          })
        );
        dispatch(incrementActions());
        // Track PMS filter update

        break;
      case "personas":
        updatedFilters = handleUpdateFilters(label, clickedLabel, event);
        dispatch(updateSelectedPersonasFilters(updatedFilters));
        dispatch(
          updateComponent({
            component: label,
            component_label: clickedLabel
          })
        );
        dispatch(incrementActions());
        // Track Personas filter update

        break;
      case "isichat":
        updatedFilters = handleUpdateFilters(label, clickedLabel, event);
        dispatch(updateSelectedISIFilters(updatedFilters));
        dispatch(
          updateSelectedRange(updatedFilters[label] ? selectedRange : "")
        );
        dispatch(
          updateComponent({
            component: label,
            component_label: clickedLabel
          })
        );
        dispatch(incrementActions());
        // Track ISIChat filter update

        break;
      case "campaignPerformance":
        updatedFilters = handleUpdateFilters(label, clickedLabel, event);
        dispatch(updateSelectedCampPerfomanceFilters(updatedFilters));
        dispatch(
          updateComponent({
            component: label,
            component_label: clickedLabel
          })
        );
        dispatch(incrementActions());
        break;
      case "ecommerce":
        if (clickedLabel === "Total no of Customers") {
          break;
        }
        updatedFilters = handleUpdateFilters(label, clickedLabel, event);
        dispatch(updateSelectedEcommerceFilters(updatedFilters));
        dispatch(
          updateComponent({
            component: label,
            component_label: clickedLabel
          })
        );
        dispatch(incrementActions());
        break;
      default:
        break;
    }
  };

  const dataValuesPlugin = isCompare
    ? []
    : [
        {
          afterDraw: (chart) => {
            const { ctx } = chart;
            chart.data.datasets.forEach((dataset, datasetIndex) => {
              const meta = chart.getDatasetMeta(datasetIndex);
              if (!meta.hidden) {
                meta.data.forEach((element, index) => {
                  if (!element.hidden) {
                    const { y } = element.tooltipPosition();
                    ctx.fillStyle = `${themeColors.graphAxisColor}`; // Font color of the labels
                    ctx.font = `550 2vh ${themeColors.fontQuickSand}`; // Font weight 500, size, and family
                    ctx.textAlign = "start";
                    ctx.textBaseline = "middle";
                    const labelName = `${chart.data.labels[index]}`;
                    ctx.fillText(labelName, 10, y); // Adjust the vertical position of the label (-20)
                  }
                });
              }
            });
          }
        }
      ];

  const plugins = [...dataValuesPlugin];
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

  return (
    <Bar
      data={data}
      ref={chartRef}
      options={options}
      height={data.datasets[0].length > 6 ? "700" : "200"}
      plugins={plugins}
      onClick={onClick}
      className="bargraph-content"
    />
  );
}

BarGraph.propTypes = {
  options: PropTypes.oneOfType([PropTypes.object]).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  dashboard: PropTypes.string,
  filterValues: PropTypes.oneOfType([PropTypes.object]),
  isCompare: PropTypes.bool,
  selectedRange: PropTypes.string
};
