/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable */
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactJsxParser from "react-jsx-parser";
import domtoimage from "dom-to-image-more";
import close from "../../../../../assets/icons/close.png";
import download from "../../GeneratedCampaign/assets/icons/download.png";
import "../assets/stylesheets/openedImageModel.scss";
import {
  incrementActions,
  updateComponent
} from "../../../../../store/slices/trackingSlice";
import { useDispatch } from "react-redux";

export default function OpenedImageModel({
  setOpenedImage,
  selectedImage,
  selectedName,
  selectedDimension
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const renderedRef = useRef(null);

  const handleDownload = async (format = "png") => {
    if (renderedRef.current) {
      try {
        const dataUrl =
          format === "png"
            ? await domtoimage.toPng(renderedRef.current, {
                width: renderedRef.current.offsetWidth,
                height: renderedRef.current.offsetHeight,
                quality: 1
              })
            : await domtoimage.toJpeg(renderedRef.current, {
                width: renderedRef.current.offsetWidth,
                height: renderedRef.current.offsetHeight,
                quality: 1
              });

        // Create a temporary download link
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `downloaded-image.${format}`;
        link.click();
        dispatch(
          updateComponent({
            component: "Download",
            component_label: `${selectedName} - ${selectedDimension}`
          })
        );
        dispatch(incrementActions());
      } catch (error) {
        console.error("Error capturing image:", error);
      }
    }
  };

  console.log("selectedImage", selectedImage);

  return (
    <div className="opened-image-model">
      <div className="flex-space preview-close">
        <div className="preview-text">{t("manageCampaign.preview")}</div>
        <div
          onClick={() => {
            setOpenedImage(false);
          }}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            // Handling keyboard events
            if (e.key === "Enter" || e.key === " ") {
              setOpenedImage(false);
            }
          }}
        >
          <img src={close} className="close-img" alt="close" />
        </div>
      </div>

      <div
        ref={renderedRef}
        className="flex-justify-center image-section-main-div"
      >
        <ReactJsxParser
          components={{}}
          jsx={selectedImage}
          renderInWrapper={false}
          autoCloseVoidElements
        />
      </div>
      {/* Image section ends */}

      {/* Image name, dimensions and download icon */}
      <div className="name-dimensions">
        <div className="flex-space">
          <div className="image-name">{selectedName}</div>
        </div>
        <div className="dimensions">{selectedDimension}</div>

        <div
          style={{
            marginTop: "15px",
            cursor: "pointer"
          }}
          onClick={() => handleDownload("png")}
          className="flex"
        >
          <div
            style={{
              paddingRight: "12px"
            }}
            className="dimensions"
          >
            Download
          </div>
          <img
            style={{
              width: "17px",
              height: "17px"
            }}
            src={download}
          />
        </div>
      </div>
      {/* Image name, dimensions and download icon */}
    </div>
  );
}
