import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import "./assets/stylesheets/selectService.scss";
import {
  incrementActions,
  updateComponent
} from "../../../store/slices/trackingSlice";

export default function Index({ manageApiServices }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="manage-api-list">
      {manageApiServices?.map((item) => (
        <div
          role="button"
          tabIndex="0"
          className="manage-api-tile flex-vertical-between"
          onClick={() => {
            navigate("/manage/api/service", { state: { service: item } });
            dispatch(
              updateComponent({
                component: t("ManageApi.title"),
                component_label: item?.api_service_name
              })
            );
            dispatch(incrementActions());
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              navigate("/manage/api/service", { state: { service: item } });
            }
          }}
        >
          <div className="flex-between">
            <div className="flex-start">
              <img src={item?.image} alt="manage-api-icon" className="logo" />
              <span className="name">{item?.api_service_name} </span>
            </div>
          </div>
          <div className="flex-between tile-bottom">
            <div className="helper-text flex-between">
              {item?.subscription_type && (
                <div className="flex-center subscription-type-height">
                  <span className="secondary-name">
                    {item?.subscription_type}
                  </span>
                </div>
              )}
            </div>
            {item?.integrated &&
              (item?.connection_enable || item?.manual_integration) && (
                <div className="flex-center subscription-type-height active-chip-color">
                  <span className="secondary-name">
                    {t("ManageApi.active")}
                  </span>
                </div>
              )}
            {((item?.integrated &&
              !item?.connection_enable &&
              ![3, 4].includes(item?.api_service_id)) ||
              (!item?.integrated && item?.manual_integration)) && (
              <div className="flex-center subscription-type-height active-disable-chip-color">
                <span className="secondary-name">
                  {[3, 4].includes(item?.api_service_id)
                    ? t("ManageApi.connectionInactive")
                    : t("ManageApi.activeDisabled")}
                </span>
              </div>
            )}
            {!item?.integrated &&
              !item?.connection_enable &&
              !item?.manual_integration && (
                <div className="flex-center subscription-type-height not-integrated-chip-color">
                  <span className="secondary-name">
                    {t("ManageApi.notIntegrated")}
                  </span>
                </div>
              )}
          </div>
        </div>
      ))}
    </div>
  );
}

Index.propTypes = {
  manageApiServices: PropTypes.oneOfType([Array]).isRequired
};
