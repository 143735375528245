/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { updateDashboardLink } from "../../store/slices/generalSlice";
import "./dropdown.scss";
import {
  incrementActions,
  updateComponent
} from "../../store/slices/trackingSlice";

export default function Dropdown({ menus, placeholder }) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const currentPath = location.pathname;

  // Get the current page name
  const currentPage = menus.find((menu) => menu.path === location.pathname);
  const currentPageName = currentPage ? currentPage.name : placeholder;

  useEffect(() => {
    dispatch(updateDashboardLink(location?.pathname));
  }, [location]);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div
        className={
          currentPage
            ? "flex-between active-page"
            : "dropdown-placeholder flex-between"
        }
        onClick={toggleDropdown}
      >
        <p>{currentPageName}</p>
        <i className="ri-arrow-down-s-line" role="menu" tabIndex="0" />
      </div>
      {isOpen && (
        <ul className="dropdown-menu">
          {menus.map((ele) => {
            if (ele.path === currentPath) {
              return null; // Exclude the current page from the dropdown items
            }
            return (
              <Link
                onClick={() => {
                  dispatch(updateDashboardLink(ele.path));
                  dispatch(
                    updateComponent({
                      component: "Header",
                      component_label: ele.name
                    })
                  );
                  dispatch(incrementActions());
                }}
                to={ele.path}
                key={ele.id}
              >
                <li className="dropdown-item">
                  <p className="page-name">{ele.name}</p>
                </li>
              </Link>
            );
          })}
        </ul>
      )}
    </div>
  );
}
