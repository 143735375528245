/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable */
import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import PlanList from "./PlanList";
import PlanTile from "./PlanTile";
import { useDispatch } from "react-redux";
import {
  incrementActions,
  updateComponent
} from "../../../../store/slices/trackingSlice";

export default function OurPlans({
  changePlan,
  plansList,
  topUpPlanList,
  currentPlan,
  currentPlanId,
  expiryDate,
  downGradedPlan,
  currentTopup,
  handlePlanClick
}) {
  const [toggleSel, setToggleSel] = useState("monthly");
  const dispatch = useDispatch();

  const subsPlan = plansList?.find((ele) => ele?.planId === currentPlan);
  let i = plansList.indexOf(subsPlan);
  let tempPlans = plansList.slice();
  tempPlans.splice(i, 1);
  const enterprisePlans = tempPlans.filter((ele) => ele.type === "enterprise");
  tempPlans = tempPlans.filter((ele) => ele.type === toggleSel);

  const tempTopupPlans = topUpPlanList.slice();
  const subsTopupPlan = topUpPlanList?.find(
    (ele) => ele?.planId === currentTopup
  );

  if (currentTopup) {
    i = topUpPlanList.indexOf(subsTopupPlan);
    tempTopupPlans.splice(i, 1);
  }

  const { t } = useTranslation();

  return (
    <div className="plan-list">
      {!changePlan && (
        <PlanTile
          showInList
          plan={subsPlan}
          currentPlan
          expiryDate={expiryDate}
          downGradedPlan={downGradedPlan}
          handlePlanClick={handlePlanClick}
        />
      )}
      {!changePlan && subsTopupPlan && (
        <PlanTile
          showInList
          plan={subsTopupPlan}
          expiryDate={expiryDate}
          downGradedPlan={downGradedPlan}
          handlePlanClick={handlePlanClick}
        />
      )}
      <div className="flex-between list-title">
        <div className="toggle-button">
          <span
            className={toggleSel === "monthly" && "selected"}
            onClick={() => {
              setToggleSel("monthly");
              dispatch(
                updateComponent({
                  component: "Subscription toggle",
                  component_label: "monthly"
                })
              );
              dispatch(incrementActions());
            }}
          >
            {t("subscription.monthly")}
          </span>
          <span
            className={toggleSel === "yearly" && "selected"}
            onClick={() => {
              setToggleSel("yearly");
              dispatch(
                updateComponent({
                  component: "Subscription toggle",
                  component_label: "yearly"
                })
              );
              dispatch(incrementActions());
            }}
          >
            {t("subscription.yearly")}
          </span>
        </div>
      </div>

      {plansList?.length > 0 && (
        <PlanList
          currentPlanId={currentPlanId}
          plansList={tempPlans}
          handlePlanClick={handlePlanClick}
        />
      )}
      {enterprisePlans?.length > 0 && (
        <PlanList
          currentPlanId={currentPlanId}
          plansList={enterprisePlans}
          handlePlanClick={handlePlanClick}
        />
      )}
      {tempTopupPlans?.length > 0 && (
        <>
          <div className="flex-between list-title">
            {t("subscription.topUpPlans")}
          </div>

          <PlanList
            plansList={tempTopupPlans}
            handlePlanClick={handlePlanClick}
          />
        </>
      )}
    </div>
  );
}

OurPlans.propTypes = {
  changePlan: PropTypes.bool,
  plansList: PropTypes.oneOfType([PropTypes.array]),
  topUpPlanList: PropTypes.oneOfType([PropTypes.array]),
  currentPlan: PropTypes.number,
  currentTopup: PropTypes.number,
  expiryDate: PropTypes.number,
  downGradedPlan: PropTypes.number,
  handlePlanClick: PropTypes.func
};
