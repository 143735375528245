/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material";
import SelectChannel from "./SelectChannel";
import SelectPersona from "./SelectPersona";
import SelectType from "./SelectType";
import Personatile from "../../../../../assets/icons/Personatile.png";
import "../assets/stylesheets/addCampaignComponent.scss";
import {
  generateTemplate,
  getCampaignDetails,
  getCategorisedImage
} from "../api/addCampaignAPI";
import { Toastr } from "../../../../../components/Toastr/Toastr";
import AuthorizedHeader from "../../../../../components/layouts/AuthorizedHeader";
import Loader from "./Loader";
import NoImageFoundModel from "./NoImageFoundModel";
import UploadImagesModel from "./UploadImagesModel";
import { getAdvertisementstyles } from "../../../../Settings/AdvertisementStyles/api/advertisementApi";
import {
  incrementActions,
  updateComponent
} from "../../../../../store/slices/trackingSlice";

const personaTypes = [
  {
    personaTypeId: "1",
    personaTypeName: "Season 1"
  },
  {
    personaTypeId: "2",
    personaTypeName: "Season 2"
  },
  {
    personaTypeId: "3",
    personaTypeName: "Season 3"
  }
];

export default function AddCampaignComponent({
  isHoveredChannel,
  showChannels,
  handleMouseEnterChannel,
  handleMouseLeaveChannel,
  setShowChannels,
  channels,
  Selectchannel,
  isHoveredType,
  isHoveredPersona,
  handleMouseEnterType,
  handleMouseLeaveType,
  handleMouseEnterPersona,
  handleMouseLeavePersona,
  types,
  hoveredChannel,
  handleSelectType,
  selectedType,
  selectedChannel,
  Selecttype,
  Selectpersona
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { websiteID, createdBy } = useSelector((state) => state.generalData);
  const navigate = useNavigate();

  const [showNoImageFoundModel, setShowNoImageFoundModel] = useState(false);
  const [showUploadImagesModel, setShowUploadImagesModel] = useState(false);

  const [loading, setLoading] = useState(false);
  const [getImagesLoading, setGetImagesLoading] = useState(false);
  const [getUploadImagesLoading, setGetUploadImagesLoading] = useState(false);

  const [selectedImage, setSelectedImage] = useState([]);

  const [campaignDetails, setCampaignDetails] = useState([]);

  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [multiSelectTypes, setMultiSelectTypes] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState("");

  const [selectedPersonas, setSelectedPersonas] = useState([]);
  const [personas, setPersonas] = useState([]);

  const [themePrompt, setThemePrompt] = useState({
    title: "",
    content: "",
    button: "",
    websiteBackground: ""
  });

  const handleSelectChannel = (channel) => {
    if (selectedChannels.some((ch) => ch.channel_id === channel.channel_id)) {
      setSelectedChannels(
        selectedChannels.filter((ch) => ch.channel_id !== channel.channel_id)
      );
    } else {
      setSelectedChannels([...selectedChannels, channel]);
    }
    dispatch(
      updateComponent({
        component: "Select Channel",
        component_label: channel?.channelName
      })
    );
    dispatch(incrementActions());
  };

  const handleSelectTypes = (type) => {
    if (multiSelectTypes.some((ty) => ty.type_id === type.type_id)) {
      setMultiSelectTypes(
        multiSelectTypes.filter((ty) => ty.type_id !== type.type_id)
      );
    } else {
      setMultiSelectTypes([...multiSelectTypes, type]);
    }
    dispatch(
      updateComponent({
        component: "Select type",
        component_label: type?.type_name
      })
    );
    dispatch(incrementActions());
  };

  const handlePersonaChange = (event) => {
    setSelectedPersona(event.target.value);
  };

  const handleCheckboxChange = (personaId, personaLabel) => {
    setSelectedPersonas((prevSelected) =>
      prevSelected.includes(personaId)
        ? prevSelected.filter((id) => id !== personaId)
        : [...prevSelected, personaId]
    );
    dispatch(
      updateComponent({
        component: "Select persona",
        component_label: personaLabel
      })
    );
    dispatch(incrementActions());
  };

  const handleGetCampaignDetails = async () => {
    setLoading(true);
    try {
      const res = await getCampaignDetails(websiteID);
      const order = [6, 1, 2, 3];

      const sortedCampaignDetails = res?.data?.campaignsData.sort(
        (a, b) => order.indexOf(a.channel_id) - order.indexOf(b.channel_id)
      );

      setCampaignDetails(sortedCampaignDetails);
      const transformedPersonas = res?.data?.persona.map((item) => ({
        persona: item.persona,
        persona_label: item.persona_label.split(",")[0]
      }));
      setPersonas(transformedPersonas);
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetCategorisedImage = async () => {
    setGetImagesLoading(true);
    const payload = {
      website_id: websiteID,
      persona: selectedPersonas
    };
    try {
      // First API call to getCategorisedImage
      const res = await getCategorisedImage(payload);
      console.log("res", res);
      if (res.data.success === true) {
        const randomObjects = res?.data?.randomObjects;

        // Second API (generate-template) with the response from the first API

        const generateTemplatePayload = randomObjects?.map((Category) => {
          return {
            ...Category,
            themePrompt: themePrompt
          };
        });

        const templateResponse = await generateTemplate(
          generateTemplatePayload
        );
        console.log("res", res, "templateResponse", templateResponse);
        const originalTemplates = templateResponse?.data?.templates;
        const cleanTemplates = templateResponse?.data?.templates?.map(
          (template) =>
            template
              .replace(/\\n/g, "")
              .replace(/\\t/g, "")
              .replace(/\\"/g, '"')
              .replace(/\\'/g, "'")
        );

        // If second API is successful, we show success message and navigate
        if (templateResponse?.status === 200) {
          Toastr.success("Success");
          navigate("/manage/generate-campaign", {
            state: {
              campaignDetails,
              randomObjects,
              originalTemplates,
              cleanTemplates,
              selectedChannels,
              multiSelectTypes,
              selectedPersonas,
              themePrompt
            }
          });
          dispatch(
            updateComponent({
              component: "Add Campaign",
              component_label: "Generate Image"
            })
          );
          dispatch(incrementActions());
        } else {
          Toastr.error("Template generation failed.");
        }
      } else {
        setShowNoImageFoundModel(true);
      }
    } catch (error) {
      setGetImagesLoading(false);
      Toastr.error("Something went wrong.");
    } finally {
      setGetImagesLoading(false);
    }
  };

  const handleGetCategorisedUploadedImage = async () => {
    setGetUploadImagesLoading(true);
    const payload = {
      website_id: websiteID,
      persona: selectedPersonas
    };
    try {
      // First API call to getCategorisedImage
      const res = await getCategorisedImage(payload);

      if (res.data.success === true) {
        const randomObjects = res?.data?.randomObjects;

        let newRandomObjects = randomObjects?.map((image) => ({
          ...image,
          imageUrl: selectedImage?.src
        }));

        // Second API (generate-template) with the response from the first API
        const generateTemplatePayload = [...newRandomObjects];
        const templateResponse = await generateTemplate(
          generateTemplatePayload
        );

        const originalTemplates = templateResponse?.data?.templates;
        const cleanTemplates = templateResponse?.data?.templates?.map(
          (template) =>
            template
              .replace(/\\n/g, "")
              .replace(/\\t/g, "")
              .replace(/\\"/g, '"')
              .replace(/\\'/g, "'")
        );

        // If second API is successful, we show success message and navigate
        if (templateResponse?.status === 200) {
          Toastr.success("Success");
          navigate("/manage/generate-campaign", {
            state: {
              campaignDetails,
              newRandomObjects,
              originalTemplates,
              cleanTemplates,
              selectedChannels,
              multiSelectTypes,
              selectedPersonas
            }
          });
        } else {
          Toastr.error("Template generation failed.");
        }
      } else {
        setShowNoImageFoundModel(true);
      }
    } catch (error) {
      setGetUploadImagesLoading(false);
      Toastr.error("Something went wrong.");
    } finally {
      setGetUploadImagesLoading(false);
    }
  };

  const getPromptText = (type, colorObj, fontFamilyObj) => {
    if (colorObj != undefined && fontFamilyObj != undefined) {
      return `update the ${type} color as **${colorObj.color}** and fontFamily as **${fontFamilyObj.fontFamily}** `;
    }
    if (colorObj != undefined) {
      return `update the ${type} color as **${colorObj.color}**`;
    }
    if (fontFamilyObj != undefined) {
      return `update the ${type}  fontFamily as **${fontFamilyObj.fontFamily}** `;
    }
    return `update the ${type} color and font-family with with unique color themes and maching fonts`;
  };

  const buildPrompt = (styles) => {
    const websiteBackgroundobj = styles.websiteBackgroundColor.find(
      (style) => style.isSelected == 1
    );
    const websiteBackground =
      websiteBackgroundobj != undefined
        ? `update the background color of the ad design with this color **${websiteBackgroundobj.color}**`
        : "update the background color of the ad design with gradient colors";

    const buttonBackgroundobj = styles.buttonBackgroundColor.find(
      (style) => style.isSelected == 1
    );
    const buttonBackground =
      buttonBackgroundobj != undefined
        ? ` and background color to  **${buttonBackgroundobj.color}**`
        : " and background color to unique color";

    const titleColor = styles.titleFontColor.find(
      (style) => style.isSelected == 1
    );

    const titleFontFamily = styles.titleFontFamily.find(
      (style) => style.isSelected == 1
    );
    const contentColor = styles.contentFontColor.find(
      (style) => style.isSelected == 1
    );
    const contentFontFamily = styles.contentFontFamily.find(
      (style) => style.isSelected == 1
    );
    const buttonColor = styles.buttonFontColor.find(
      (style) => style.isSelected == 1
    );
    const buttonFontFamily = styles.buttonFontFamily.find(
      (style) => style.isSelected == 1
    );

    const title = getPromptText("title", titleColor, titleFontFamily);
    const content = getPromptText("content", contentColor, contentFontFamily);
    const button = getPromptText(
      "call to action button",
      buttonColor,
      buttonFontFamily
    );
    setThemePrompt((theme) => ({
      websiteBackground,
      title,
      content,
      button: button + buttonBackground
    }));
  };

  // Api call to get Advertisement styles
  const handleGetAdvertismentStyles = async () => {
    try {
      const resp = await getAdvertisementstyles(`${websiteID}`);
      if (resp?.data?.result?.success) {
        console.log("advertisement styles", resp?.data?.result?.result);
        if (resp?.data?.result?.result !== undefined) {
          buildPrompt(resp?.data?.result?.result);
        }
      } else {
        console.log("get data failed");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const order = [6, 1, 2, 3];

    const sortedCampaignDetails = [...campaignDetails].sort(
      (a, b) => order.indexOf(a.channel_id) - order.indexOf(b.channel_id)
    );

    setCampaignDetails(sortedCampaignDetails);
  }, []);

  useEffect(() => {
    const allTypes = selectedChannels?.map((channel) => channel.type).flat();

    setSelectedTypes(allTypes);
  }, [selectedChannels]);

  useEffect(() => {
    const validTypeIds = selectedChannels
      .map((channel) => channel.type.map((type) => type.type_id))
      .flat();

    const updatedMultiSelectTypes = multiSelectTypes.filter((type) =>
      validTypeIds.includes(type.type_id)
    );

    setMultiSelectTypes(updatedMultiSelectTypes);
  }, [selectedChannels]);

  const newPersonaTypes = personaTypes?.map((persona) => ({
    value: persona?.personaTypeId,
    label: persona?.personaTypeName
  }));

  // useEffect to get the advertimenst styles
  useEffect(() => {
    handleGetAdvertismentStyles();
  }, []);

  console.log(
    "selectedPersonas",
    selectedPersonas,
    "selectedImage",
    selectedImage,
    "campaignDetails",
    campaignDetails
  );

  return (
    <>
      <AuthorizedHeader />
      <div className="container">
        <div className="add-campaign background-box">
          <div className="add-set-campaign modules-heading">
            <p className="modules-heading">{t("manageCampaign.addCampaign")}</p>
            <div className="set-campaign-text">
              {t("manageCampaign.setCampaign")}
            </div>
          </div>

          {/* Select Channel,type and persona */}
          <div className="flex">
            {/* SelectChannel */}
            <SelectChannel
              isHoveredChannel={isHoveredChannel}
              showChannels={showChannels}
              handleMouseEnterChannel={handleMouseEnterChannel}
              handleMouseLeaveChannel={handleMouseLeaveChannel}
              setShowChannels={setShowChannels}
              channels={channels}
              hoveredChannel={hoveredChannel}
              selectedChannel={selectedChannel}
              handleSelectChannel={handleSelectChannel}
              Selectchannel={Selectchannel}
              selectedChannels={selectedChannels}
              loading={loading}
              campaignDetails={campaignDetails}
              handleGetCampaignDetails={handleGetCampaignDetails}
            />
            {/* SelectChannel ends */}

            {/* SelectType */}
            <SelectType
              isHoveredType={isHoveredType}
              handleMouseEnterType={handleMouseEnterType}
              handleMouseLeaveType={handleMouseLeaveType}
              selectedChannel={selectedChannel}
              types={types}
              hoveredChannel={hoveredChannel}
              handleSelectType={handleSelectType}
              handleSelectTypes={handleSelectTypes}
              selectedType={selectedType}
              selectedChannels={selectedChannels}
              selectedTypes={selectedTypes}
              Selecttype={Selecttype}
              multiSelectTypes={multiSelectTypes}
            />
            {/* SelectType ends */}

            {/* SelectPersona */}
            <div>
              <SelectPersona
                isHoveredPersona={isHoveredPersona}
                selectedType={selectedType}
                handleMouseEnterPersona={handleMouseEnterPersona}
                handleMouseLeavePersona={handleMouseLeavePersona}
                selectedPersona={selectedPersona}
                t={t}
                newPersonaTypes={newPersonaTypes}
                handlePersonaChange={handlePersonaChange}
                personas={personas}
                selectedPersonas={selectedPersonas}
                setSelectedPersonas={setSelectedPersonas}
                handleCheckboxChange={handleCheckboxChange}
                Selectpersona={Selectpersona}
                multiSelectTypes={multiSelectTypes}
              />
            </div>
            {/* SelectPersona ends */}
          </div>
          {/* Select Channel, type and persona ends */}

          {getImagesLoading ? (
            <Loader text={t("manageCampaign.generatingImages")} />
          ) : (
            // Button

            <div
              className={`flex-center generate-button ${
                selectedPersonas?.length >= 1 && "selected"
              }`}
              onClick={() => {
                if (selectedPersonas?.length > 0) {
                  handleGetCategorisedImage();
                }
              }}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                // Handling keyboard events
                if (e.key === "Enter" || e.key === " ") {
                  if (selectedPersonas?.length > 0) {
                    handleGetCategorisedImage();
                  }
                }
              }}
            >
              <div
                className={`generate-button-text ${
                  selectedPersonas?.length >= 1 && "selected"
                }`}
              >
                {t("manageCampaign.generateImage")}
              </div>
            </div>
            // Button ends
          )}

          <div
            className="use-images"
            onClick={() => {
              if (selectedPersonas?.length > 0) {
                setShowUploadImagesModel(true);
              }
            }}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              // Handling keyboard events
              if (e.key === "Enter" || e.key === " ") {
                if (selectedPersonas?.length > 0) {
                  setShowUploadImagesModel(true);
                }
              }
            }}
          >
            <div
              className={
                selectedPersonas.length > 0
                  ? "use-images-text-selected"
                  : "use-images-text"
              }
            >
              <div>{t("manageCampaign.useUploadedImages")}</div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={showNoImageFoundModel}
        onClose={() => {
          setShowNoImageFoundModel(false);
        }}
        maxWidth="md"
        fullWidth
        className="user-management"
        PaperProps={{
          style: {
            minHeight: "12vw",
            padding: "0px"
          }
        }}
      >
        <NoImageFoundModel
          setShowNoImageFoundModel={setShowNoImageFoundModel}
          setShowUploadImagesModel={setShowUploadImagesModel}
        />
      </Dialog>

      <Dialog
        open={showUploadImagesModel}
        onClose={() => {
          setShowUploadImagesModel(false);
        }}
        maxWidth="md"
        fullWidth
        className="user-management"
        PaperProps={{
          style: {
            minHeight: "12vw",
            padding: "0px"
          }
        }}
      >
        <UploadImagesModel
          setShowUploadImagesModel={setShowUploadImagesModel}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          getUploadImagesLoading={getUploadImagesLoading}
          handleNaviagetToEdit={() => {
            handleGetCategorisedUploadedImage();
            // navigate("/manage/edit-design", {
            //   state: {
            //     campaignDetails,
            //     // randomObjects,
            //     // originalTemplates,
            //     // cleanTemplates,
            //     selectedChannels,
            //     multiSelectTypes,
            //     selectedPersonas,
            //     selectedImage
            //   }
            // });
          }}
        />
      </Dialog>
    </>
  );
}
