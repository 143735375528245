/* eslint-disable */
import axios from "../../api/axios";
import Toastr from "../../components/Toastr/Toastr";
import {
  incrementActions,
  updateComponent
} from "../../store/slices/trackingSlice";
import { deleteImages, getAllImages } from "../apis/uploadImagesAPI";

export const handleGetAllImages = async ({
  setLoader,
  websiteID,
  setAllImages,
  skippedImages
}) => {
  setLoader(true);
  try {
    const res = await getAllImages(websiteID);
    console.log("res====>", res);
    let skippedImagesIds = skippedImages?.map((image) => image?.image_id);
    const allImages =
      res?.data?.imageUrl?.length > 0 ? res?.data?.imageUrl : [];
    const finalImages = allImages?.filter(
      (image) => !skippedImagesIds.includes(image?.image_id)
    );
    console.log(
      "skippedImages==>",
      skippedImages,
      "skippedImagesIds",
      skippedImagesIds,
      "allImages",
      allImages,
      "finalImages",
      finalImages
    );

    setAllImages(finalImages);
    setLoader(false);
  } catch (error) {
    setLoader(false);
    console.error("Error fetching images:", error);
  } finally {
    setLoader(false); // Ensure setLoading is called after the operation
  }
};

export const handleUploadAllImages = async ({
  uploadedImageDetails,
  setLoading,
  websiteID,
  handleAddImagePopUpClose,
  getImages,
  t,
  dispatch
}) => {
  if (uploadedImageDetails?.length === 0) {
    Toastr.error(t("uploadImages.uploadValidation"));
  } else {
    setLoading(true);
    const formData = new FormData();
    const fileNames = uploadedImageDetails.map((image) => image.name);
    uploadedImageDetails?.forEach((image) => {
      formData?.append("file", image?.file);
    });
    formData.append("website_id", websiteID);
    formData.append("file", JSON.stringify(fileNames));
    try {
      const response = await axios.post("wiget/uploadImage", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      setLoading(false);
      handleAddImagePopUpClose();
      Toastr.success("Images uploaded successfully");
      await getImages();
      console.log("Upload successful", response.data);
      dispatch(
        updateComponent({
          component: "Upload Images",
          component_label: "Upload All"
        })
      );
      dispatch(incrementActions());
    } catch (error) {
      console.error("Error uploading images", error);
      setLoading(false);
    } finally {
      setLoading(false); // Ensure setLoading is called after the operation
    }
  }
};

export const handleDeleteAllImages = async ({
  setLoading,
  websiteID,
  selectedImagesNew,
  photos,
  deletedIds,
  allImages,
  setPhotos,
  setAllImages,
  setShowDeletePopup,
  setImagePopup,
  getImages,
  dispatch
}) => {
  setLoading(true);
  try {
    const res = await deleteImages({
      website_id: websiteID,
      images: selectedImagesNew
    });
    console.log("res====>", res);
    if (res?.data?.success) {
      const updatedPhotos = photos?.filter(
        (photo) => !deletedIds.includes(photo.image_id)
      );
      const updatedListPhotos = allImages?.filter(
        (photo) => !deletedIds.includes(photo.image_id)
      );
      setPhotos(updatedPhotos);
      setAllImages(updatedListPhotos);
      Toastr.success("Deleted images successfully");
      setShowDeletePopup(false);
      setImagePopup(false);
      dispatch(
        updateComponent({
          component: t("uploadImages.uploadImages"),
          component_label: "Delete"
        })
      );
      dispatch(incrementActions());
    } else {
      Toastr.error("Failed to delete images. Please try again.");
    }
    await getImages();
  } catch (error) {
    console.log(error);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const handleAllFileUpload = ({
  files,
  formatFileSize,
  setUploadedImageDetails,
  setUploadedImages
}) => {
  const newImages = Array.from(files).map((file) => ({
    name: file?.name.replace(/\s+/g, ""), // Remove all spaces from the file name
    date: new Date().toLocaleDateString(),
    url: URL.createObjectURL(file),
    file: file,
    formattedSize: formatFileSize(file.size)
  }));
  const newImageNames = Array.from(files).map((file) =>
    file?.name.replace(/\s+/g, "")
  ); // Remove spaces from file names
  console.log("newImageNames", newImageNames);
  setUploadedImageDetails((prevImages) => [...prevImages, ...newImages]);
  setUploadedImages((prevImages) => [...prevImages, ...newImageNames]);
};
