import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import "./assets/stylesheets/categories.scss";
import themeColors from "../../../assets/stylesheets/_var.scss";
import ToggleSwitch from "../../../components/ToggleSwitch/ToggleSwitch";
import { getAllCategories, updateCategoriesStatus } from "./api/categoriesApi";
import Loader from "../../../components/Loader/Loader";
import { Toastr } from "../../../components/Toastr/Toastr";
import {
  incrementActions,
  updateComponent
} from "../../../store/slices/trackingSlice";

export default function Index() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { websiteID } = useSelector((state) => state.generalData);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(false);
  const [enableAll, setEnableAll] = useState(false);
  const [disableAll, setDisableAll] = useState(false);

  const getCategoriesList = async () => {
    try {
      setLoader(true);
      const resp = await getAllCategories(websiteID);
      if (resp?.data?.result) {
        setData(resp.data.result);
        setEnableAll(resp.data.enableAll);
        setDisableAll(resp.data.disableAll);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    getCategoriesList();
  }, [websiteID]);

  const handleToggle = async (category) => {
    const filterdata = data
      .filter((ele) => ele.id === category.id) // Filter elements matching the condition
      .map((ele) => ({ id: ele.id, status: !ele.status }));
    const payload = {
      website_id: websiteID,
      toggle: filterdata,
      disableAll: false,
      enableAll: false
    };
    try {
      setSelectedCategory(category.id);
      setLoader(true);
      const resp = await updateCategoriesStatus(payload);
      if (resp?.data?.success) {
        Toastr.success(resp?.data?.message);
        setData(resp.data.result);
        setEnableAll(resp.data.enableAll);
        setDisableAll(resp.data.disableAll);
      }
      setLoader(false);
      setSelectedCategory(false);
    } catch (error) {
      setLoader(false);
      setSelectedCategory(false);
      console.log("error", error);
    }
  };

  const handleEnableAll = async () => {
    const payload = {
      website_id: websiteID,
      toggle: [],
      enableAll: true,
      disableAll: false
    };
    try {
      setSelectedCategory("enableAll");
      setLoader(true);
      const resp = await updateCategoriesStatus(payload);
      if (resp?.data?.success) {
        Toastr.success(resp?.data?.message);
        setData(resp.data.result);
        setEnableAll(resp.data.enableAll);
        setDisableAll(resp.data.disableAll);
        dispatch(
          updateComponent({
            component: t("settings.categories"),
            component_label: t("common.enableAll")
          })
        );
        dispatch(incrementActions());
      }
      setLoader(false);
      setSelectedCategory(false);
    } catch (error) {
      setLoader(false);
      setSelectedCategory(false);
      console.log("error", error);
    }
  };

  const handleDeselectAll = async () => {
    const payload = {
      website_id: websiteID,
      toggle: [],
      disableAll: true,
      enableAll: false
    };
    try {
      setSelectedCategory("disableAll");
      setLoader(true);
      const resp = await updateCategoriesStatus(payload);
      if (resp?.data?.success) {
        Toastr.success(resp?.data?.message);
        setData(resp.data.result);
        setEnableAll(resp.data.enableAll);
        setDisableAll(resp.data.disableAll);
      }
      setLoader(false);
      setSelectedCategory(false);
      dispatch(
        updateComponent({
          component: t("settings.categories"),
          component_label: t("common.deselectAll")
        })
      );
      dispatch(incrementActions());
    } catch (error) {
      setLoader(false);
      setSelectedCategory(false);
      console.log("error", error);
    }
  };

  return (
    <div className="categories">
      <div className="header-content flex-between">
        <div className="modules-heading">{t("settings.categories")}</div>
      </div>
      {loader && !selectedCategory ? (
        <Loader />
      ) : (
        <>
          <div className="flex-end">
            <div className="flex">
              <button
                type="button"
                onClick={() => {
                  handleDeselectAll();
                }}
                className={`enable-all ${
                  loader || disableAll ? "disableEnableAll" : ""
                }`}
                disabled={loader || disableAll}
              >
                {t("common.deselectAll")}
              </button>
              {loader && selectedCategory === "disableAll" && (
                <CircularProgress
                  sx={{
                    color: themeColors.primaryColorShade2,
                    margin: "10px 0px"
                  }}
                  size={22}
                  thickness={6}
                />
              )}
            </div>
            <div className="flex">
              <button
                type="button"
                onClick={() => {
                  handleEnableAll();
                }}
                className={`enable-all ${
                  loader || enableAll ? "disableEnableAll" : ""
                }`}
                disabled={loader || enableAll}
              >
                {t("common.enableAll")}
              </button>
              {loader && selectedCategory === "enableAll" && (
                <div>
                  <CircularProgress
                    sx={{
                      color: themeColors.primaryColorShade2,
                      margin: "10px 0px"
                    }}
                    size={22}
                    thickness={6}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="categories-container">
            <div className="flex-between margin10">
              <div className="categories-header">
                {t("common.standardCategories")}
              </div>
              <div className="categories-header">{t("common.enable")}</div>
            </div>
            {data.map((item) => (
              <div className="flex-between margin15">
                <div className="categories-name">{item.category_label}</div>
                <div>
                  {loader && selectedCategory === item.id ? (
                    <CircularProgress
                      sx={{
                        color: themeColors.primaryColorShade2,
                        marginRight: "15px"
                      }}
                      size={22}
                      thickness={6}
                    />
                  ) : (
                    <ToggleSwitch
                      isToggled={item?.status}
                      label=""
                      onPress={() => handleToggle(item)}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
