/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Dialog, DialogTitle } from "@mui/material";
import CustomAccordian from "../../../../components/Accordion/CustomAccordian";
import { ButtonDark } from "../../../../components/inputElements/buttons/MainButton";
import CloseButton from "../../../../assets/icons/closeModal.svg";
import CategoryPills from "./CategoryPills";
import OurPlans from "./OurPlans";
import DataConsumption from "./DataConsumption";
import PlanDetails from "./PlanDetails";
import DisplayBillingInfo from "./DisplayBillingInfo";
import AddEditBillingInfo from "./AddEditBillingInfo";
import DisplayAllAddresses from "./DisplayAllAddresses";
import comingSoon from "../../../../assets/icons/comingSoon.svg";
import DropdownComponent from "../../../../components/inputElements/selectInput/DropdownComponent";
import Loader from "../../../../components/Loader/Loader";
import { useDispatch } from "react-redux";
import {
  incrementActions,
  updateComponent
} from "../../../../store/slices/trackingSlice";

export default function Subscription({
  websitesList,
  websiteID,
  handleWebsiteChange,
  userEmail,
  countryISO,
  loading,
  plansLoading,
  usageLoading,
  pageUsage,
  monthlyUsage,
  billingAddresses,
  categoryPills,
  plansList,
  topUpPlanList,
  currentPlan,
  currentPlanId,
  expiryDate,
  downGradedPlan,
  currentTopup,
  planSelect,
  pillSelect,
  addressSelect,
  modalContent,
  handlePillSelect,
  handlePlanClick,
  handleChangeModal,
  handleCloseModal,
  handleAddressSelect,
  fetchVivaWallet,
  handleCancelSubscription
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const newWebsitesList = websitesList?.map((website, index) => ({
    created_by: website?.created_by,
    dashboard_id: website?.dashboard_id,
    icon: website?.icon,
    id: website?.id,
    integrated: website?.integrated,
    pinned: website?.pinned,
    role_id: website?.role_id,
    track_token: website?.track_token,
    url: website?.url,
    name: website?.name,
    label: website?.name,
    value: website?.id
  }));

  return (
    <div className="billing-subs">
      <div className="flex-between">
        <div className="modules-heading">{t("subscription.title")}</div>

        <DropdownComponent
          value={websiteID}
          name="websiteList"
          id="websiteList"
          options={newWebsitesList}
          onChange={(event) => {
            handleWebsiteChange(Number(event.target.value));
            dispatch(
              updateComponent({
                component: "Website selection dropdown",
                component_label: event.target.value.toString()
              })
            );
            dispatch(incrementActions());
          }}
          isRequired={false}
          minWidth={150}
          marginTop="7px"
        />
      </div>
      {categoryPills?.length > 0 && pillSelect && (
        <CategoryPills
          pills={categoryPills}
          pillSelect={pillSelect}
          handlePillSelect={handlePillSelect}
        />
      )}

      {plansLoading && <Loader />}

      {!plansLoading &&
        (plansList?.length > 0 ? (
          <>
            <CustomAccordian
              expanded
              title={t("subscription.ourPlans")}
              loading={plansLoading}
            >
              {plansList?.length > 0 && (
                <OurPlans
                  plansList={plansList}
                  topUpPlanList={topUpPlanList}
                  currentPlan={currentPlan}
                  currentPlanId={currentPlanId}
                  expiryDate={expiryDate}
                  downGradedPlan={downGradedPlan}
                  currentTopup={currentTopup}
                  handlePlanClick={handlePlanClick}
                />
              )}
            </CustomAccordian>

            {/* Billing information */}
            <CustomAccordian
              className="billing-accordian"
              title={t("subscription.billingInformation")}
              expanded
              loading={loading}
            >
              {!loading &&
                (billingAddresses?.length > 0 ? (
                  <div className="display-billing">
                    <DisplayBillingInfo
                      id={addressSelect?.billingId}
                      value={addressSelect}
                      handleChangeModal={handleChangeModal}
                    />
                    <ButtonDark
                      buttonText={t("subscription.billing.change")}
                      className="mt-1"
                      onClick={() =>
                        handleChangeModal("changeAddress", { address: null })
                      }
                    />
                  </div>
                ) : (
                  <AddEditBillingInfo
                    email={userEmail}
                    countryISO={countryISO}
                    handleChangeModal={handleChangeModal}
                  />
                ))}
            </CustomAccordian>
            {/* Billing information ends */}

            {(monthlyUsage || pageUsage) && (
              <CustomAccordian
                expanded
                title={t("subscription.billing.pageViewsConsumptionDetails")}
                loading={usageLoading}
              >
                <DataConsumption
                  pageUsage={pageUsage}
                  pageViews={monthlyUsage}
                />
              </CustomAccordian>
            )}
          </>
        ) : (
          <div className="flex-vertical coming-soon">
            <img src={comingSoon} alt="coming-soon" />
            <span>{t("comingSoon.heading")}</span>
          </div>
        ))}
      <div className="overflow-x-scroll">
        <Dialog
          open={modalContent?.open}
          onlose={handleCloseModal}
          scroll="paper"
          maxWidth="md"
          fullWidth
          className="billing-subs plan-list"
        >
          {/* Wrap content in a container with horizontal scroll */}
          {modalContent?.children === "changeAddress" && (
            <>
              <DialogTitle className="flex-between">
                {t("subscription.changeBillingAddress")}
                <img
                  src={CloseButton}
                  alt="close-button"
                  className="close-button"
                  onClick={handleCloseModal}
                />
              </DialogTitle>
              <DialogTitle className="display-all-address">
                <div className="flex-end">
                  <button
                    type="button"
                    className="add-new"
                    onClick={() =>
                      handleChangeModal("editAddress", { address: null })
                    }
                  >
                    {t("subscription.addNew")}
                  </button>
                </div>
              </DialogTitle>
              <DisplayAllAddresses
                addressList={billingAddresses}
                selected={addressSelect}
                handleAddressSelect={handleAddressSelect}
                handleChangeModal={handleChangeModal}
                handleCloseModal={handleCloseModal}
              />
            </>
          )}
          {modalContent?.children === "editAddress" && (
            <>
              <DialogTitle className="flex-between">
                {t("subscription.billingInformation")}
                <img
                  src={CloseButton}
                  alt="close-button"
                  className="close-button"
                  onClick={handleCloseModal}
                />
              </DialogTitle>
              <AddEditBillingInfo
                email={userEmail}
                countryISO={countryISO}
                value={billingAddresses.find(
                  (ele) => ele.billingId === modalContent.address
                )}
                handleChangeModal={handleChangeModal}
              />
            </>
          )}
          {modalContent?.children === "paymentDetails" && (
            <>
              <DialogTitle className="flex-between">
                {t("subscription.paymentDetails")}
                <img
                  src={CloseButton}
                  alt="close-button"
                  className="close-button"
                  onClick={handleCloseModal}
                />
              </DialogTitle>
              <PlanDetails
                pillSelect={pillSelect}
                fetchVivaWallet={fetchVivaWallet}
                website={websitesList.filter((ele) => ele.id === websiteID)[0]}
                handleCancelSubscription={handleCancelSubscription}
                currentPlan={currentPlan}
                expiryDate={expiryDate}
                downGradedPlan={downGradedPlan}
                currentTopup={currentTopup}
                planSelect={planSelect}
                address={addressSelect}
                handleChangeModal={handleChangeModal}
                handleCloseModal={handleCloseModal}
              />
            </>
          )}
          {modalContent?.children === "changePlan" && (
            <>
              <DialogTitle className="flex-between">
                {t("subscription.changePlan")}
                <img
                  src={CloseButton}
                  alt="close-button"
                  className="close-button"
                  onClick={handleCloseModal}
                />
              </DialogTitle>

              {plansList?.length > 0 && (
                <OurPlans
                  changePlan
                  plansList={plansList}
                  topUpPlanList={topUpPlanList}
                  currentPlan={currentPlan}
                  currentPlanId={currentPlanId}
                  expiryDate={expiryDate}
                  downGradedPlan={downGradedPlan}
                  currentTopup={currentTopup}
                  handlePlanClick={handlePlanClick}
                />
              )}
            </>
          )}
        </Dialog>
      </div>
    </div>
  );
}

Subscription.propTypes = {
  websitesList: PropTypes.oneOfType([PropTypes.array]).isRequired,
  websiteID: PropTypes.number.isRequired,
  handleWebsiteChange: PropTypes.func,
  userEmail: PropTypes.string.isRequired,
  countryISO: PropTypes.oneOfType([PropTypes.object]).isRequired,
  loading: PropTypes.bool.isRequired,
  plansLoading: PropTypes.bool.isRequired,
  usageLoading: PropTypes.bool.isRequired,
  pageUsage: PropTypes.oneOfType([PropTypes.object]).isRequired,
  monthlyUsage: PropTypes.oneOfType([PropTypes.array]).isRequired,
  billingAddresses: PropTypes.oneOfType([PropTypes.array]).isRequired,
  categoryPills: PropTypes.oneOfType([PropTypes.array]).isRequired,
  plansList: PropTypes.oneOfType([PropTypes.array]).isRequired,
  topUpPlanList: PropTypes.oneOfType([PropTypes.array]).isRequired,
  currentPlan: PropTypes.number.isRequired,
  expiryDate: PropTypes.number,
  downGradedPlan: PropTypes.number,
  currentTopup: PropTypes.number.isRequired,
  planSelect: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pillSelect: PropTypes.oneOfType([PropTypes.object]).isRequired,
  addressSelect: PropTypes.oneOfType([PropTypes.object]).isRequired,
  modalContent: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handlePillSelect: PropTypes.func.isRequired,
  handlePlanClick: PropTypes.func.isRequired,
  handleChangeModal: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleAddressSelect: PropTypes.func.isRequired,
  fetchVivaWallet: PropTypes.func.isRequired,
  handleCancelSubscription: PropTypes.func.isRequired
};
