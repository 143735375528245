/* eslint-disable */
import React, { useState } from "react";
import close from "../../../../../assets/icons/close.png";
import { useTranslation } from "react-i18next";
import {
  ButtonDark,
  ButtonLight
} from "../../../../../components/inputElements/buttons/MainButton";
import { useLocation, useNavigate } from "react-router-dom";
import AuthorizedHeader from "../../../../../components/layouts/AuthorizedHeader";
import ReactJsxParser from "react-jsx-parser";
import { generateMultipleDimensions } from "../../AddCampaign/api/addCampaignAPI";
import { Toastr } from "../../../../../components/Toastr/Toastr";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../AddCampaign/components/Loader";
import { handleGenerateMultipleDimensions } from "../../../services/addCampaignServices";

export default function UsedDesignPreviewComponent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    cleanTemplates,
    selectedRandomObject,
    template,
    selectedChannels,
    multiSelectTypes,
    selectedPersonas,
    selectedImage,
    content,
    cta,
    heading
  } = location.state || {};
  const { websiteID } = useSelector((state) => state.generalData);

  const [campaignName, setCampaignName] = useState("");
  const [adRedirectUrl, setAdRedirectUrl] = useState("");
  const [ctaButtonUrl, setCtaButtonUrl] = useState("");
  const [useSameUrl, setUseSameUrl] = useState(false);
  const [errors, setErrors] = useState("");

  const [loading, setLoading] = useState(false);

  const channelNames = selectedChannels?.map((channel) =>
    channel.channelName.toLowerCase()
  );

  const handleInputChange =
    (setter, fieldName, useSameUrl = false, setCtaButtonUrl = null) =>
    (e) => {
      const newValue = e.target.value;

      // Set the value for the current field
      setter(newValue);

      // Clear the errors for the current field
      setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: undefined }));

      // // Check if the field is 'adRedirectUrl' and handle the special logic
      if (fieldName === "adRedirectUrl" && useSameUrl && setCtaButtonUrl) {
        setCtaButtonUrl(newValue);
      }
    };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setUseSameUrl(isChecked);

    if (isChecked) {
      setCtaButtonUrl(adRedirectUrl);
    } else {
      setCtaButtonUrl("");
    }
  };

  const generateMultipleDimensions = async () => {
    const payload = {
      campaignName: campaignName,
      adRedirectUrl: adRedirectUrl,
      ctaButtonUrl: ctaButtonUrl,
      websiteId: websiteID,
      campaignChannels: channelNames,
      designTemplate: template
    };

    await handleGenerateMultipleDimensions({
      setLoading,
      payload,
      navigate,
      cleanTemplates,
      template,
      selectedRandomObject,
      selectedChannels,
      campaignName,
      adRedirectUrl,
      ctaButtonUrl,
      multiSelectTypes,
      selectedPersonas,
      selectedImage,
      content,
      cta,
      heading,
      setErrors,
      dispatch
    });
  };

  const validateForm = () => {
    let newErrors = {};

    const trimmedCampaignName = campaignName.trim();
    const trimmedAdRedirectUrl = adRedirectUrl.trim();
    const trimmedCtaButtonUrl = useSameUrl
      ? trimmedAdRedirectUrl
      : ctaButtonUrl.trim();

    const urlPattern = new RegExp(
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/
    );

    if (!trimmedCampaignName) {
      newErrors.campaignName = (
        <p className="input-error-text">
          {t("manageCampaign.campaignNameRequired")}
        </p>
      );
    }

    if (!trimmedAdRedirectUrl) {
      newErrors.adRedirectUrl = (
        <p className="input-error-text">{t("manageCampaign.adUrlRequired")}</p>
      );
    }

    if (trimmedAdRedirectUrl && !urlPattern.test(trimmedAdRedirectUrl)) {
      newErrors.adRedirectUrl = (
        <p className="input-error-text">{t("manageCampaign.adUrlValid")}</p>
      );
    }

    if (!trimmedCtaButtonUrl) {
      newErrors.ctaButtonUrl = (
        <p className="input-error-text">{t("manageCampaign.ctaUrlRequired")}</p>
      );
    }

    if (trimmedCtaButtonUrl && !urlPattern.test(trimmedCtaButtonUrl)) {
      newErrors.ctaButtonUrl = (
        <p className="input-error-text">{t("manageCampaign.ctaUrlValid")}</p>
      );
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setErrors("");
      generateMultipleDimensions();
    }
  };

  return (
    <>
      <AuthorizedHeader />

      <div className="container">
        <div className="generated-designs background-box">
          <div className="generated-designs-margins">
            <div className="flex-between">
              <div className="modules-heading">
                <p className="modules-heading">
                  {t("manageCampaign.nameCampaign")}
                </p>
              </div>
              <div className="close-icon">
                <img
                  src={close}
                  onClick={() => {
                    navigate(-1);
                  }}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    // Handling keyboard events
                    if (e.key === "Enter" || e.key === " ") {
                      navigate(-1);
                    }
                  }}
                  v
                  className="close-icon-img"
                />
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="flex-space">
                {/* Input field */}
                <div className="input-width">
                  {/* Label */}
                  <div className="generated-designs-campaign-name">
                    {t("manageCampaign.campaignName")}
                  </div>
                  {/* Label ends */}

                  {/* Input field */}
                  <div>
                    <input
                      type="text"
                      className={
                        errors.campaignName
                          ? "input-field-common-error"
                          : "input-field-common input-placeholder"
                      }
                      value={campaignName}
                      onChange={handleInputChange(
                        setCampaignName,
                        "campaignName"
                      )}
                      //   placeholder={placeholder}
                    />
                    {errors.campaignName}
                  </div>

                  {/* Input field ends */}

                  {/* Label */}
                  <div
                    style={{
                      paddingTop: "5%"
                    }}
                    className="generated-designs-campaign-name"
                  >
                    {t("manageCampaign.adUrl")}
                  </div>
                  {/* Label ends */}

                  {/* Input field */}
                  <div>
                    <input
                      type="text"
                      className={
                        errors.adRedirectUrl
                          ? "input-field-common-error"
                          : "input-field-common input-placeholder"
                      }
                      value={adRedirectUrl}
                      // onChange={handleAdRedirectUrlChange}
                      // onChange={handleAdRedirectUrlChange(
                      //   setAdRedirectUrl,
                      //   "adRedirectUrl"
                      // )}
                      onChange={handleInputChange(
                        setAdRedirectUrl,
                        "adRedirectUrl",
                        useSameUrl,
                        setCtaButtonUrl
                      )}

                      //   placeholder={placeholder}
                    />
                    {errors.adRedirectUrl}
                  </div>
                  {/* Input field ends */}

                  {/* Label */}
                  <div
                    style={{
                      paddingTop: "5%"
                    }}
                    className="generated-designs-campaign-name"
                  >
                    {t("manageCampaign.ctaUrl")}
                  </div>
                  {/* Label ends */}

                  {/* Input field */}
                  <div>
                    <input
                      type="text"
                      className={
                        errors.ctaButtonUrl
                          ? "input-field-common-error"
                          : "input-field-common input-placeholder"
                      }
                      value={ctaButtonUrl}
                      // onChange={(e) => {
                      //   setCtaButtonUrl(e.target.value);
                      // }}
                      onChange={handleInputChange(
                        setCtaButtonUrl,
                        "ctaButtonUrl"
                      )}
                      //   placeholder={placeholder}
                    />
                    {errors.ctaButtonUrl}
                  </div>
                  {/* Input field ends */}

                  <div
                    style={{
                      paddingTop: "1%"
                    }}
                  >
                    <label htmlFor="web-link" className="web-link flex-start">
                      <input
                        type="checkbox"
                        id="web-link"
                        name="website-link"
                        onChange={handleCheckboxChange}
                      />
                      {t("manageCampaign.useSame")}
                    </label>
                  </div>
                </div>
                {/* Input field ends */}

                {/* Previewed image */}
                <div className="preview-campaign-background">
                  <div>
                    <div className="preview-campaign-text">
                      {t("manageCampaign.preview")}
                    </div>
                  </div>
                  <div>
                    <ReactJsxParser
                      components={{}}
                      jsx={template}
                      renderInWrapper={false}
                      autoCloseVoidElements
                    />
                  </div>
                </div>
                {/* Previewed image ends */}
              </div>

              {loading ? (
                <Loader text={t("manageCampaign.completingCampaign")} />
              ) : (
                <div className="flex-center">
                  <div className="cancel-button-padding-right">
                    <ButtonLight
                      buttonText={t("common.confirmation.cancel")}
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      paddingRight: "12px"
                    }}
                  >
                    <ButtonDark
                      isSubmit
                      buttonText={t("manageCampaign.generateImages")}
                    />
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
