/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonDark,
  ButtonLight
} from "../../../../../components/inputElements/buttons/MainButton";
import collapse from "../../SavedCampaign/assets/icons/collapse.png";
import expand from "../../SavedCampaign/assets/icons/expand.png";
import "../assets/stylesheets/generatedCampaignFinal.scss";
import AuthorizedHeader from "../../../../../components/layouts/AuthorizedHeader";
import { useLocation, useNavigate } from "react-router-dom";
import WebAdvertisement from "../../SavedCampaign/components/WebAdvertisement";
import { Dialog } from "@mui/material";
import OpenedImageModel from "../../SavedCampaign/components/OpenedImageModel";
import { addCampaignImages } from "../../AddCampaign/api/addCampaignAPI";
import { useDispatch, useSelector } from "react-redux";
import { Toastr } from "../../../../../components/Toastr/Toastr";
import Loader from "../../AddCampaign/components/Loader";
import { handleSaveCampaign } from "../../../services/addCampaignServices";
import GoogleMarketingImages from "../../SavedCampaign/components/GoogleMarketingImages";

export default function GeneratedCampaignFinal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { websiteID } = useSelector((state) => state.generalData);

  const {
    selectedRandomObject,
    selectedChannels,
    multiDimensionAds = {},
    template,
    campaignName,
    multiSelectTypes,
    selectedPersonas,
    adRedirectUrl,
    ctaButtonUrl,
    cleanTemplates,
    selectedImageNew,
    content,
    cta,
    heading
  } = location.state || {};

  console.log(
    "selectedRandomObject",
    selectedRandomObject,
    "selectedImageNew",
    selectedImageNew
  );

  const [loading, setLoading] = useState(false);

  const [showWebAds, setShowWebAds] = useState(true);
  const [showGoogleAds, setShowGoogleAds] = useState(true);
  const [openedImage, setOpenedImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [campaignBanners, setCampaignBanners] = useState([]);

  const [campaignInfo, setCampaignInfo] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedDimension, setSelectedDimension] = useState("");

  const webTemplates = multiDimensionAds?.website?.map((web) => web?.template);
  const googleTemplates = multiDimensionAds?.google?.map(
    (google) => google?.template
  );

  console.log("multiDimensionAds", multiDimensionAds);

  // const encodedAds = multiDimensionAds?.website?.map((ad) => ({
  //   imageLink: selectedImageNew
  //     ? selectedImageNew?.src
  //     : selectedRandomObject?.imageUrl,
  //   name: ad?.name,
  //   dimensions: ad?.dimensions,
  //   template: btoa(ad?.template)
  // }));

  // const encodedAds = Object.keys(multiDimensionAds || {}).flatMap((channel) =>
  //   multiDimensionAds[channel]?.map((ad) => ({
  //     imageLink: selectedImageNew
  //       ? selectedImageNew?.src
  //       : selectedRandomObject?.imageUrl,
  //     name: ad?.name,
  //     dimensions: ad?.dimensions,
  //     template: btoa(ad?.template)
  //   }))
  // );

  const normalizedMultiDimensionAds = Object.fromEntries(
    Object.entries(multiDimensionAds || {}).map(([channel, ads]) => [
      channel,
      Array.isArray(ads) ? ads : Object.values(ads || {})
    ])
  );

  const encodedAds = Object.keys(normalizedMultiDimensionAds).flatMap(
    (channel) =>
      normalizedMultiDimensionAds[channel].map((ad) => ({
        imageLink: selectedImageNew
          ? selectedImageNew?.src
          : selectedRandomObject?.imageUrl,
        name: ad?.name,
        dimensions: ad?.dimensions,
        template: ad?.template ? btoa(ad?.template) : ""
      }))
  );

  // Function to encode HTML template dynamically using btoa
  const encodeTemplate = (template) => {
    return btoa(template);
  };

  // Function to dynamically encode all templates in an ads structure
  const encodeAdsTemplates = () => {
    // Create a deep copy of the ads object to ensure the original data is not mutated
    const encodedAds = JSON.parse(JSON.stringify(multiDimensionAds));

    // Iterate over the copied ads object and encode templates dynamically
    for (const platform in encodedAds) {
      encodedAds[platform].forEach((ad) => {
        ad.template = encodeTemplate(ad.template);
      });
    }
    return encodedAds;
  };

  console.log(
    "encodeAdsTemplates",
    encodeAdsTemplates(),
    "encodedAds",
    encodedAds
  );

  let websiteAds = selectedChannels?.some(
    (channel) => channel?.channel_id === 6
  );

  let googleAds = selectedChannels?.some(
    (channel) => channel?.channel_id === 1
  );

  console.log("websiteAds", websiteAds, "googleAds", googleAds);

  useEffect(() => {
    const newCampaigns = selectedChannels.map((channel) => {
      // Extract type_ids from the channel's types
      const channelTypeIds = channel.type.map((type) => type.type_id);

      // Filter the types based on multiSelectTypes for the current channel
      const relevantTypes = multiSelectTypes.filter((type) =>
        channelTypeIds.includes(type.type_id)
      );

      let channelName = channel.channelName?.toLowerCase();

      const encodedAds = encodeAdsTemplates();

      // Map over encodedAds for the current channel to ensure correct mapping
      const advertismentImages = Array.isArray(encodedAds[channelName]) // Ensure the key exists and is an array
        ? encodedAds[channelName].map((ad) => ({
            imageLink: selectedRandomObject?.imageUrl || "", // Provide a fallback for imageLink
            template: ad.template || "", // Provide a fallback for template
            dimensions: ad.dimensions,
            name: ad.name
          }))
        : []; // Fallback to an empty array if the channel doesn't exist

      console.log("advertismentImages:", advertismentImages);

      return {
        website_id: websiteID,
        channel_id: channel.channel_id,
        type_id: relevantTypes.map((type) => type.type_id),
        campaign_name: campaignName,
        redirect_url: adRedirectUrl,
        cta_url: ctaButtonUrl,
        persona: selectedPersonas,
        // advertisment_image: encodedAds.map((tmpl) => ({
        //   imageLink: selectedRandomObject.imageUrl,
        //   template: tmpl
        // })),
        advertisment_image: advertismentImages, // Fallback to an empty array if no ads for the channel        title: selectedImageNew ? heading : selectedRandomObject?.title,
        title: selectedImageNew ? heading : selectedRandomObject?.title,
        content: selectedImageNew ? content : selectedRandomObject?.content,
        cta: selectedImageNew ? cta : selectedRandomObject?.cta
      };
    });

    setCampaignInfo(newCampaigns);
  }, [
    selectedChannels,
    selectedPersonas,
    multiSelectTypes,
    selectedRandomObject,
    multiDimensionAds
  ]);

  console.log(
    "selectedChannels",
    selectedChannels,
    "encodeAdsTemplates",
    encodeAdsTemplates(),
    "encodedAds",
    encodedAds,
    "campaignInfo",
    JSON.stringify(campaignInfo),
    "normalizedMultiDimensionAds",
    normalizedMultiDimensionAds
  );

  const removeAttributesFromATags = (webTemplates) => {
    return webTemplates?.map((template) =>
      template.replace(/<a\b[^>]*>/gi, (match) => {
        // Remove href, target, and rel attributes by replacing them with an empty string
        return match
          .replace(/\s*href="[^"]*"/gi, "") // Removes the href attribute
          .replace(/\s*target="[^"]*"/gi, "") // Removes the target attribute
          .replace(/\s*rel="[^"]*"/gi, ""); // Removes the rel attribute
      })
    );
  };

  const removeAttributesFromAllChannels = (ads) => {
    console.log("removeAttributesFromAllChannels called with:", ads);
    const channels = Object.keys(ads); // Extract all channel names (e.g., "website", "google")
    // Iterate over each channel
    const updatedAds = {};
    channels.forEach((channel) => {
      updatedAds[channel] = ads[channel].map((ad) => {
        // Modify the `template` string to remove specific attributes
        const updatedTemplate = ad.template
          .replace(/\s*href="[^"]*"/gi, "") // Removes href attribute
          .replace(/\s*target="[^"]*"/gi, "") // Removes target attribute
          .replace(/\s*rel="[^"]*"/gi, ""); // Removes rel attribute

        // Return a new ad object with the updated template
        return { ...ad, template: updatedTemplate };
      });
    });

    console.log("updatedAds", updatedAds);

    return updatedAds;
  };

  useEffect(() => {
    console.log("multiDimensionAds on load:", multiDimensionAds);

    // const updatedTemplates = removeAttributesFromATags(webTemplates);
    if (Object.keys(multiDimensionAds).length > 0) {
      // Only run if multiDimensionAds has data
      const updatedTemplates =
        removeAttributesFromAllChannels(multiDimensionAds);
      setCampaignBanners(updatedTemplates);
    }
  }, [multiDimensionAds]);

  const saveCampaign = async () => {
    await handleSaveCampaign(campaignInfo, setLoading, navigate, dispatch); // Call the reusable function
  };

  console.log(
    "campaignBanners====>",
    campaignBanners,
    "campaignInfo",
    campaignInfo,
    "multiDimensionAds====>",
    multiDimensionAds,
    "selectedImage",
    selectedImage,
    "heading",
    heading,
    "content",
    content,
    "cta",
    cta
  );

  return (
    <>
      <AuthorizedHeader />

      <div className="container">
        <div
          className="generated-designs background-box"
          style={
            {
              // width: "1370px"
            }
          }
        >
          <div className="generated-designs-margins">
            <div className="flex-column-height">
              <div>
                <div className="modules-heading flex-between">
                  <p className="modules-heading">{campaignName}</p>
                  <div
                    className="bottom-margin"
                    style={{
                      marginRight: "10px"
                    }}
                  >
                    <ButtonDark
                      buttonText={t("ManageApi.back")}
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                  </div>
                </div>

                {/* <div className="images-text">
                  <p>Google images</p>gen
                </div>

                <div className="images-text">
                  <p>Facebook images</p>
                </div> */}

                {/* Ads scroll container */}
                <div className="scroll-container">
                  {/* Web Ads */}
                  {websiteAds && (
                    <div
                      className="flex-pointer"
                      onClick={() => {
                        setShowWebAds(!showWebAds);
                      }}
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e) => {
                        // Handling keyboard events
                        if (e.key === "Enter" || e.key === " ") {
                          setShowWebAds(!showWebAds);
                        }
                      }}
                    >
                      <div className="images-text">
                        {t("manageCampaign.webAdvertisement")}
                      </div>

                      {showWebAds ? (
                        <img src={expand} className="expand-collapse-icons" />
                      ) : (
                        <img src={collapse} className="expand-collapse-icons" />
                      )}
                    </div>
                  )}

                  {websiteAds && showWebAds ? (
                    <div>
                      <WebAdvertisement
                        // webImages={multiDimensionAds}
                        orginalTemplateCreate={multiDimensionAds}
                        webImages={campaignBanners}
                        setOpenedImage={setOpenedImage}
                        setSelectedImage={setSelectedImage}
                        setSelectedName={setSelectedName}
                        setSelectedDimension={setSelectedDimension}
                        navigationFrom="createFlow"
                      />
                    </div>
                  ) : null}
                  {/* Web Ads end */}

                  {/* Google ads */}
                  {googleAds && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        setShowGoogleAds(!showGoogleAds);
                      }}
                    >
                      <div
                        style={{
                          paddingTop: "20px"
                        }}
                        className="images-text"
                      >
                        {t("manageCampaign.googleAds")}
                      </div>

                      {showGoogleAds ? (
                        <img
                          src={expand}
                          style={{
                            width: "24px",
                            height: "24px"
                          }}
                        />
                      ) : (
                        <img
                          src={collapse}
                          style={{
                            width: "24px",
                            height: "24px"
                          }}
                        />
                      )}
                    </div>
                  )}

                  {googleAds && showGoogleAds ? (
                    <div>
                      <GoogleMarketingImages
                        orginalTemplateCreate={multiDimensionAds}
                        googleImages={campaignBanners}
                        setOpenedImage={setOpenedImage}
                        setSelectedImage={setSelectedImage}
                        setSelectedName={setSelectedName}
                        setSelectedDimension={setSelectedDimension}
                        navigationFrom="createFlow"
                      />
                    </div>
                  ) : null}
                  {/* Google ads end */}

                  {/* Facebook and insta ads */}
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      setShowFacebookAds(!showFacebookAds);
                    }}
                  >
                    <div
                      style={{
                        paddingTop: "20px"
                      }}
                      className="images-text"
                    >
                      Facebook marketing images
                    </div>

                    {showFacebookAds ? (
                      <img
                        src={expand}
                        style={{
                          width: "24px",
                          height: "24px"
                        }}
                      />
                    ) : (
                      <img
                        src={collapse}
                        style={{
                          width: "24px",
                          height: "24px"
                        }}
                      />
                    )}
                  </div> */}

                  {/* {showFacebookAds ? (
                    <div>
                      <FacebookAndInstaAds
                        setOpenedImage={setOpenedImage}
                        setSelectedImage={setSelectedImage}
                      />
                    </div>
                  ) : null} */}
                  {/* Facebook and insta ads end */}
                </div>
                {/* Ads scroll container ends */}
              </div>

              {loading ? (
                <Loader text={t("manageCampaign.savingCampaign")} />
              ) : (
                <div className="flex-center buttons-top">
                  <div className="cancel-button-padding-right">
                    <ButtonLight
                      buttonText={t("manageCampaign.editCampaign")}
                      onClick={() => {
                        navigate("/manage/edit-design", {
                          state: {
                            selectedRandomObject,
                            cleanTemplates,
                            template,
                            selectedChannels,
                            multiSelectTypes,
                            selectedPersonas,
                            navigationFrom: selectedImageNew
                              ? "designPage"
                              : "",
                            generatedHeading: heading,
                            generatedContent: content,
                            generatedCta: cta,
                            selectedImage: selectedImageNew
                          }
                        });
                      }}
                    />
                  </div>
                  <ButtonDark
                    buttonText={t("manageCampaign.saveCampaign")}
                    onClick={saveCampaign}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openedImage}
        onClose={() => {
          setOpenedImage(false);
          setSelectedImage(null);
        }}
        maxWidth="md"
        fullWidth
        className="user-management"
        PaperProps={{
          style: {
            minHeight: "12vw",
            padding: "0px"
          }
        }}
      >
        <OpenedImageModel
          selectedImage={selectedImage}
          setOpenedImage={setOpenedImage}
          selectedName={selectedName}
          selectedDimension={selectedDimension}
        />
      </Dialog>
    </>
  );
}
