/* eslint-disable */
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getWebsites,
  pinWebsite,
  unPinWebsite,
  updateWebsiteDetails,
  addWebsiteApi,
  trackTokenAPI,
  getCompanyType,
  startCollectingData,
  modifyDefaultWebsite,
  removeWebsite
} from "./api/myWebsitesAPI";
import MyWebsites from "./components/MyWebsites";
import { Toastr } from "../../components/Toastr/Toastr";
import {
  updateDefWebsite,
  updateWebsiteID,
  updateWebsitesList
} from "../../store/slices/generalSlice";
import storage from "../../utils/storage";
import {
  incrementActions,
  updateComponent
} from "../../store/slices/trackingSlice";

export default function Index() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { defaultWebsite } = useSelector((state) => state.generalData);

  const [pageLoading, setPageLoading] = useState(false);
  const [websitePinLoading, setWebsitePinLoading] = useState(false);

  const [websitesList, setWebsitesList] = useState([]);
  const [companyTypes, setCompanyTypes] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddWebsiteOpen, setIsAddWebsiteOpen] = useState(false);

  const fetchWebsites = async () => {
    setPageLoading(true);
    try {
      const res = await getWebsites();
      setWebsitesList(res.data.websites);
      dispatch(updateWebsitesList(res.data.websites));
      storage.setItem("websitesList", JSON.stringify(res.data.websites));
      if (defaultWebsite !== res.data.defaultWebsite) {
        dispatch(updateDefWebsite(res.data.defaultWebsite));
        storage.setItem("defaultWeb", res.data.defaultWebsite);
      }
      setPageLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) {
        setWebsitesList([]);
        dispatch(updateWebsitesList([]));
        storage.setItem("websitesList", JSON.stringify([]));
        dispatch(updateDefWebsite(null));
        storage.setItem("defaultWeb", null);
      }
      setPageLoading(false);
    }
  };

  const updateDefaultWebsiteCommon = async (id, shouldFetchWebsites) => {
    setWebsitePinLoading(id);
    const selectedWebsiteId = id;
    try {
      await modifyDefaultWebsite({ website_id: selectedWebsiteId });
      storage.setItem("defaultWeb", selectedWebsiteId);
      const role = websitesList.find((ele) => ele.id === id);
      if (role) {
        storage.setItem("role", role.role_id);
        storage.setItem("createdBy", role.created_by);
        dispatch(updateDefWebsite(selectedWebsiteId));
        dispatch(
          updateWebsiteID({
            role_id: role.role_id,
            website_id: id,
            created_by: role.created_by
          })
        );
      }

      if (shouldFetchWebsites) {
        await fetchWebsites();
      }

      setWebsitePinLoading(false);
    } catch (error) {
      setWebsitePinLoading(false);
    }
  };

  const updateNonDefaultWebsite = async (id) => {
    setWebsitePinLoading(id);
    try {
      await modifyDefaultWebsite({
        website_id: null
      });
      storage.setItem("defaultWeb", null);
      const role = websitesList.find((ele) => ele.id === id);
      storage.setItem("role", role.role_id);
      storage.setItem("createdBy", role.created_by);
      dispatch(updateDefWebsite(null));
      dispatch(
        updateWebsiteID({
          role_id: null,
          website_id: null,
          created_by: null
        })
      );
      await fetchWebsites();
      setWebsitePinLoading(false);
    } catch (error) {
      console.log(error);
      setWebsitePinLoading(false);
    }
  };

  const fetchCompanyType = async () => {
    try {
      const res = await getCompanyType();
      setCompanyTypes(res.data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenModal = (id) => {
    setIsModalOpen(id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const deleteWebsite = async (list) => {
    try {
      console.log("success");
      await removeWebsite({
        websites: [...list]
      });
      await fetchWebsites();
      dispatch(
        updateComponent({
          component: "My sites",
          component_label: "Delete"
        })
      );
      dispatch(incrementActions());
    } catch (error) {
      console.log("error", error);
    }
  };

  const updatePinWebsite = async (id, name) => {
    setWebsitePinLoading(id);
    try {
      await pinWebsite({
        website_id: id
      });
      await fetchWebsites();
      setWebsitePinLoading(false);
      dispatch(
        updateComponent({
          component: "Pin website",
          component_label: name
        })
      );
      dispatch(incrementActions());
    } catch (error) {
      console.log(error);
      setWebsitePinLoading(false);
    }
  };

  const updateUnPinWebsite = async (id, name) => {
    setWebsitePinLoading(id);
    try {
      await unPinWebsite({
        website_id: id
      });
      await fetchWebsites();
      setWebsitePinLoading(false);
      dispatch(
        updateComponent({
          component: "Unpin website",
          component_label: name
        })
      );
      dispatch(incrementActions());
    } catch (error) {
      console.log(error);
      setWebsitePinLoading(false);
    }
  };

  const addWebsiteName = async (id, name) => {
    try {
      await updateWebsiteDetails({
        website_id: id,
        website_name: name
      });
      await fetchWebsites();
      dispatch(
        updateComponent({
          component: "My sites",
          component_label: "Edit"
        })
      );
      dispatch(incrementActions());
    } catch (error) {
      console.log(error);
    }
  };

  const addNewWebsite = async (url, name, type) => {
    try {
      const res = await addWebsiteApi({
        url,
        website_name: name,
        company_type: type
      });
      fetchWebsites();
      return res.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getWebsiteTrackToken = async (id) => {
    try {
      const res = await trackTokenAPI({
        website_id: id
      });
      return res.data.result[0].track_token;
      //   await fetchWebsites();
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const collectData = async (id) => {
    try {
      const response = await startCollectingData({
        website_id: id
      });

      if (response.data.result[0].integrated_flag === 1) {
        Toastr.success(t("addScript.completed"));
        dispatch(
          updateComponent({
            component: "My sites",
            component_label: t("addWebsite.collectData")
          })
        );
        dispatch(incrementActions());
        return true;
      }
      await fetchWebsites();
      Toastr.error(t("addScript.noIncomingData"));
      return false;
    } catch (error) {
      Toastr.error(t("addScript.failedToStart"));
      return false;
    }
  };

  const modifyWebsiteID = (id) => {
    // updates the website ID in redux (globally)
    const role = websitesList.find((ele) => ele.id === id);
    storage.setItem("defaultWeb", id);
    storage.setItem("role", role.role_id);
    storage.setItem("createdBy", role.created_by);
    dispatch(
      updateWebsiteID({
        role_id: role.role_id,
        website_id: id,
        created_by: role.created_by
      })
    );
    // if (
    //   location?.pathname === "/analytics" ||
    //   "/pms" ||
    //   "/personas" ||
    //   "/customers" ||
    //   "/sessions" ||
    //   "/widget/performance" ||
    //   "/campaign/performance" ||
    //   "/isichat"
    // ) {
    //   navigate(dashBoardLink);
    // } else {
    navigate("/analytics");
    // }
  };

  useEffect(() => {
    if (websitesList?.length === 1) {
      updateDefaultWebsiteCommon(websitesList[0]?.id, false);
    }
  }, [websitesList]);

  useEffect(() => {
    fetchWebsites();
    fetchCompanyType();
  }, []);

  return (
    <MyWebsites
      pageLoading={pageLoading}
      websitePinLoading={websitePinLoading}
      websiteDetails={{ websitesList, defaultWebsite }}
      updateDefaultWebsite={updateDefaultWebsiteCommon}
      updateNonDefaultWebsite={updateNonDefaultWebsite}
      isModalOpen={isModalOpen}
      handleOpenModal={handleOpenModal}
      isAddWebsiteOpen={isAddWebsiteOpen}
      setIsAddWebsiteOpen={setIsAddWebsiteOpen}
      handleCloseModal={handleCloseModal}
      deleteWebsite={deleteWebsite}
      updatePinWebsite={updatePinWebsite}
      updateUnPinWebsite={updateUnPinWebsite}
      addWebsiteName={addWebsiteName}
      addNewWebsite={addNewWebsite}
      getWebsiteTrackToken={getWebsiteTrackToken}
      companyTypes={companyTypes}
      collectData={collectData}
      modifyWebsiteID={modifyWebsiteID}
      showPopUp={location?.state?.showPopUp || false}
      websiteId={location?.state?.websiteId}
    />
  );
}
