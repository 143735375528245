/* eslint-disable */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import themeColors from "../../../assets/stylesheets/_var.scss";
import { useDispatch } from "react-redux";
import {
  incrementActions,
  updateComponent
} from "../../../store/slices/trackingSlice";

export default function SearchFilter({
  searchFilters,
  searchFilter,
  setSearchFilter
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setSearchFilter(event.target.value);
    dispatch(
      updateComponent({
        component: "Search filter",
        component_label: event.target.value
      })
    );
    dispatch(incrementActions());
  };

  return (
    <FormControl
      variant="outlined"
      style={{
        minWidth: 200
        // height: "3rem"
      }}
    >
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={searchFilter}
        onChange={handleChange}
        label="Search Filter"
        style={{
          fontFamily: themeColors.fontQuickSand,
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "0.875rem",
          borderColor: themeColors.tertiaryText
        }}
        sx={{
          height: "2.5rem",
          "& .MuiSelect-select": {
            padding: "8px",
            height: "auto"
          }
        }}
      >
        {searchFilters?.map((filter, index) => {
          return (
            <MenuItem
              key={index}
              style={{
                fontFamily: themeColors.fontQuickSand,
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "0.875rem"
              }}
              value={filter?.value}
            >
              {t("common.searchBy")} {filter?.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
