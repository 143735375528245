import { useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Doughnut, getElementsAtEvent } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "../assets/stylesheets/donutChart.scss";
import {
  incrementActions,
  updateComponent
} from "../../../../store/slices/trackingSlice";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DonutChart({
  data,
  options,
  updateFilters,
  selectedFilters
}) {
  const chartRef = useRef();
  const dispatch = useDispatch();

  const handleChartClick = (event) => {
    const clickedElements = getElementsAtEvent(chartRef.current, event);
    if (clickedElements.length === 0) return;

    const { index } = clickedElements[0];
    const { label } = data.datasets[0];
    const clickedLabel = data.labels[index];
    const currentFilters = selectedFilters[label] || [];

    const updatedFilters = { ...selectedFilters };

    dispatch(
      updateComponent({
        component: label,
        component_label: clickedLabel
      })
    );
    dispatch(incrementActions());

    if (currentFilters.includes(clickedLabel)) {
      updatedFilters[label] = currentFilters.filter(
        (item) => item !== clickedLabel
      );
    } else if (event.metaKey || event.ctrlKey) {
      updatedFilters[label] = [...currentFilters, clickedLabel];
    } else {
      updatedFilters[label] = [clickedLabel];
    }

    if (updatedFilters[label].length === 0) {
      delete updatedFilters[label];
    }

    dispatch(updateFilters(updatedFilters));
  };

  return (
    <Doughnut
      data={data}
      ref={chartRef}
      options={options}
      onClick={handleChartClick}
    />
  );
}

DonutChart.propTypes = {
  options: PropTypes.oneOfType([PropTypes.object]).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateFilters: PropTypes.func,
  selectedFilters: PropTypes.oneOfType([Object])
};
