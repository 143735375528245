/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import i18n from "../../../config/i18n";
import languageApi from "../../../api/language";
import LocalStorage from "../../../utils/LocalStorgae";
import storage from "../../../utils/storage";
import GermanFlag from "./assets/icons/german.svg";
import UsFlag from "./assets/icons/us.svg";
import ItalyFlag from "./assets/icons/italy.svg";
import "./assets/stylesheets/languageDropdown.scss";
import {
  incrementActions,
  updateComponent
} from "../../../store/slices/trackingSlice";

export default function Index({ showLabel }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { t } = useTranslation();
  const token = storage.getToken();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const countryData = [
    { name: t("languages.german"), flag: GermanFlag, initials: "de" },
    { name: t("languages.english"), flag: UsFlag, initials: "en" },
    { name: t("languages.italian"), flag: ItalyFlag, initials: "it" }
  ];

  const handleLanguageChange = (initials) => {
    // Set the selected language in local storage and update i18n
    LocalStorage.setItem("selectedLanguage", initials);
    i18n.changeLanguage(initials);
    if (token) {
      languageApi.updateLanguage({ language: initials });
    }
    setIsOpen(false);
    dispatch(
      updateComponent({
        component: "Change Language",
        component_label: initials
      })
    );
    dispatch(incrementActions());
  };

  const selectedLanguage = LocalStorage.getItem("selectedLanguage");

  // Check if selectedLanguage is available before rendering
  if (!selectedLanguage) {
    return null; // or render a loading state or default flag
  }

  return (
    <div className="language-dropdown" ref={dropdownRef}>
      <div className="default-icon flex-between" onClick={toggleDropdown}>
        {showLabel && <p>{t("hamburgerHeader.changeLanguage")}</p>}
        <img
          src={
            countryData.find((country) => country.initials === selectedLanguage)
              .flag
          }
          alt="us-flag"
        />
      </div>
      {isOpen && (
        <div className="language-dropdown-items">
          {countryData.map((country) => (
            <div
              key={country.name}
              className="language-item flex-between"
              onClick={() => handleLanguageChange(country.initials)}
            >
              <p>{country.name}</p>
              <img src={country.flag} alt={country.name} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

Index.propTypes = {
  showLabel: PropTypes.bool
};
