/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactDOMServer from "react-dom/server";
import HeatMap from "react-heatmap-grid";
import "./assets/stylesheets/heatmapWithLabels.scss";
import themeColors from "../../assets/stylesheets/_var.scss";
import ChartHeader from "../ChartHeader/ChartHeader";
import { Tooltip } from "react-tooltip";
import { convertToK } from "../../utils/dataFilter";
import info from "../../assets/icons/info_active.svg";
import { IconButton, Dialog } from "@mui/material";
import CloseIcon from "../../assets/icons/closeModal.svg";
import ProfileIcon from "../../assets/icons/profile.svg";

export default function Index({
  heatmap1Data,
  heatmap2Data,
  heatmap3Data,
  isToggled,
  dashboard,
  selectedFilters,
  updateFilters,
  filterType,
  companyType
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openModel, setOpenModel] = useState(false);

  const data2 = Array(heatmap2Data[0]);
  const data4 = Array(heatmap2Data[1]);

  const data3 = heatmap3Data.slice(0, 2);
  const data5 = heatmap3Data.slice(2, 4);

  const handleOnclick = (value, count, title) => {
    if (count === 0) return;
    if (
      selectedFilters.hasOwnProperty(title) &&
      selectedFilters[title].includes(value)
    ) {
      dispatch(updateFilters({}));
    } else {
      const selectedMap = {};
      selectedMap[title] = Array(value);
      dispatch(updateFilters(selectedMap));
    }
  };

  const legendColors = {
    0: themeColors.fourthText,
    14: themeColors.primaryColor2,
    30: themeColors.primaryColor1Shade2,
    45: themeColors.primaryColor1Shade1,
    100: themeColors.primaryColor
  };

  const sortedKeys = Object.keys(legendColors)
    .map(Number)
    .sort((a, b) => a - b);

  const getPercentage = (value, total) => {
    if (value === 0) return 0;
    const percentageValue = ((Math.round(value) / total) * 100)
      .toFixed(2)
      .replaceAll(".", ",");
    if (percentageValue === "100,00") return 100;
    return percentageValue;
  };

  const getValueFromLabel = (data, value) => {
    return data
      .flatMap((row) => row.filter((cell) => value === cell.label))
      .find((cell) => cell !== undefined);
  };

  const getCountOfValues = (data) =>
    data.flat().reduce((total, item) => total + item.value, 0);

  const getColorFromLegend = (value, data, title) => {
    const res = getValueFromLabel(data, value);

    let countOfValues;
    if (title === t("heatmap.heatmap_3")) {
      countOfValues = getCountOfValues(heatmap3Data);
    } else if (title === t("heatmap.heatmap_2")) {
      countOfValues = getCountOfValues(heatmap2Data);
    } else if (title === t("heatmap.heatmap_1")) {
      countOfValues = getCountOfValues(data);
    }
    const checkPercentage = (res.value / countOfValues) * 100;
    for (let i = 0; i < sortedKeys.length; i++) {
      if (checkPercentage <= sortedKeys[i]) {
        return legendColors[sortedKeys[i]];
      }
    }
    return themeColors.fourthText;
  };

  const getFilterTypeTransalation = (filterType) => {
    if (filterType === "Visitors") {
      return t("analyticsPage.visitors");
    }
    if (filterType === "Views") {
      return t("analyticsPage.views");
    }
    return t("analyticsPage.revenue");
  };

  const tooltipHeatmapContent = (value, x, y, data) => {
    const filterTypeTransalation = getFilterTypeTransalation(filterType);
    const res = data
      .flatMap((row) => row.filter((cell) => value === cell.label))
      .find((cell) => cell !== undefined);
    return (
      <div>
        <div className="heatmap-padding-bottom">
          <span className="tooltip-title"> {value}</span>
        </div>
        <div className="tooltip-heatmap">
          <span> {`${filterTypeTransalation}: ${res?.value}`}</span>
        </div>
      </div>
    );
  };
  const handleInfo = () => {
    setOpenModel(true);
  };

  const handleClose = () => {
    setOpenModel(false);
  };

  return (
    <div className="bargraph-body background-box">
      <ChartHeader
        title={t("personaDashboard.personas")}
        personaHeatmap={legendColors}
        bookingPercentage={true}
        dashboard={dashboard}
      />
      {isToggled && companyType === 1 && (
        <div className="heatmap-padding-top">
          <HeatMap
            xLabels={["", "", "", ""]}
            yLabels={[""]}
            xLabelWidth={0}
            yLabelWidth={0}
            data={heatmap1Data?.map((row) =>
              row.map((cell, index) => {
                return { value: cell.label, id: index };
              })
            )}
            squares={false}
            height={60}
            title={(value, unit) => ""}
            tooltip={false}
            cellStyle={(background, value, min, max, data, x, y) => ({
              background: getColorFromLegend(
                value?.value,
                heatmap1Data,
                t("heatmap.heatmap_1")
              ),
              fontSize: "18px",
              fontWeight: 700,
              lineHeight: "20.8px",
              color: themeColors.background,
              paddingTop: "0px"
            })}
            cellRender={(value, x, y) => {
              const res = getValueFromLabel(heatmap1Data, value?.value);
              const countOfValues = getCountOfValues(heatmap1Data);
              const checkPercentage = getPercentage(res?.value, countOfValues);
              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent click from propagating to the parent
                    handleOnclick(
                      value?.value,
                      res?.value,
                      t("heatmap.heatmap_1")
                    );
                  }}
                  className="heatmap-box-cursor"
                >
                  <div>{value?.value}</div>
                  <span className="heatmap-label-text">
                    {filterType === "Revenue"
                      ? `${convertToK(res?.value)} €`
                      : convertToK(res?.value)}
                    &nbsp;
                    {`(${checkPercentage}%)`}
                  </span>
                  {/* {Object.keys(selectedFilters)?.length > 0 &&
                    res?.value !== 0 && (
                      <img
                        src={info}
                        alt="info-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleInfo();
                        }}
                        className="info-icon"
                      />
                    )} */}
                </div>
              );
            }}
          />
        </div>
      )}
      <div className="heatmap-padding-top">
        <HeatMap
          xLabels={["", ""]}
          yLabels={[""]}
          xLabelWidth={0}
          yLabelWidth={0}
          data={data2?.map((row) =>
            row.map((cell, index) => {
              return { value: cell.label, id: index };
            })
          )}
          squares={false}
          height={60}
          title={(value, unit) => ""}
          tooltip={false}
          cellStyle={(background, value, min, max, data, x, y) => ({
            background: getColorFromLegend(
              value?.value,
              data2,
              t("heatmap.heatmap_2")
            ),
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "20.8px",
            color: themeColors.background,
            margin: value?.id === 0 ? "1px 10px 0px 0px" : "1px 1px 0px 0px"
          })}
          cellRender={(value, x, y) => {
            const res = getValueFromLabel(data2, value?.value);
            const countOfValues = getCountOfValues(heatmap2Data);
            const checkPercentage = getPercentage(res?.value, countOfValues);
            return (
              <div
                onClick={(e) => {
                  e.stopPropagation(); // Prevent click from propagating to the parent
                  handleOnclick(
                    value?.value,
                    res?.value,
                    t("heatmap.heatmap_2"),
                    res
                  );
                }}
                className="heatmap-box-cursor"
              >
                <div>{value?.value}</div>
                <span className="heatmap-label-text">
                  {filterType === "Revenue"
                    ? `${convertToK(res?.value)} €`
                    : convertToK(res?.value)}
                  &nbsp;
                  {`(${checkPercentage}%)`}
                </span>
                {/* {Object.keys(selectedFilters)?.length > 0 &&
                  res?.value !== 0 && (
                    <img
                      src={info}
                      alt="info-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleInfo();
                      }}
                      className="info-icon"
                    />
                  )} */}
              </div>
            );
          }}
        />
      </div>
      <div>
        <HeatMap
          xLabels={["", "", "", ""]}
          yLabels={["", ""]}
          xLabelWidth={0}
          yLabelWidth={0}
          data={data3?.map((row) =>
            row.map((cell, index) => {
              return { value: cell.label, id: index };
            })
          )}
          squares={false}
          height={70}
          tooltipDataAttrs={() => ({})}
          title={(value, unit) => ""}
          tooltip={false}
          cellStyle={(background, value, label, min, max, data, x, y) => ({
            background: getColorFromLegend(
              value?.value,
              data3,
              t("heatmap.heatmap_3")
            ),
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "20.8px",
            color: themeColors.background,
            margin: value?.id === 1 ? "1px 10px 0px 0px" : "1px 1px 0px 0px"
          })}
          cellRender={(value, x, y) => {
            const res = getValueFromLabel(data3, value?.value);
            const countOfValues = getCountOfValues(heatmap3Data);
            const checkPercentage = getPercentage(res?.value, countOfValues);
            return (
              <div
                onClick={(e) => {
                  e.stopPropagation(); // Prevent click from propagating to the parent
                  handleOnclick(
                    value?.value,
                    res?.value,
                    t("heatmap.heatmap_3")
                  );
                }}
                className="heatmap-box-cursor"
              >
                <div>{value?.value}</div>
                <span className="heatmap-label-text">
                  {filterType === "Revenue"
                    ? `${convertToK(res?.value)} €`
                    : convertToK(res?.value)}
                  &nbsp;
                  {`(${checkPercentage}%)`}
                </span>
                {/* {Object.keys(selectedFilters)?.length > 0 &&
                  res?.value !== 0 && (
                    <img
                      src={info}
                      alt="info-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleInfo();
                      }}
                      className="info-icon"
                    />
                  )} */}
              </div>
            );
          }}
        />
      </div>
      <div className="heatmap-padding-top">
        <HeatMap
          xLabels={["", ""]}
          yLabels={[""]}
          xLabelWidth={0}
          yLabelWidth={0}
          data={data4?.map((row) =>
            row.map((cell, index) => {
              return { value: cell.label, id: index };
            })
          )}
          squares={false}
          height={60}
          title={(value, unit) => ""}
          tooltip={false}
          cellStyle={(background, value, min, max, data, x, y) => ({
            background: getColorFromLegend(
              value?.value,
              data4,
              t("heatmap.heatmap_2")
            ),
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "20.8px",
            color: themeColors.background,
            margin: value?.id === 0 ? "1px 10px 0px 0px" : "1px 1px 0px 0px"
          })}
          cellRender={(value, x, y) => {
            const res = getValueFromLabel(data4, value?.value);
            const countOfValues = getCountOfValues(heatmap2Data);
            const checkPercentage = getPercentage(res?.value, countOfValues);
            return (
              <div
                onClick={(e) => {
                  e.stopPropagation(); // Prevent click from propagating to the parent
                  handleOnclick(
                    value?.value,
                    res?.value,
                    t("heatmap.heatmap_2")
                  );
                }}
                className="heatmap-box-cursor"
              >
                <div>{value?.value}</div>
                <span className="heatmap-label-text">
                  {filterType === "Revenue"
                    ? `${convertToK(res?.value)} €`
                    : convertToK(res?.value)}
                  &nbsp;
                  {`(${checkPercentage}%)`}
                </span>
                {/* {Object.keys(selectedFilters)?.length > 0 &&
                  res?.value !== 0 && (
                    <img
                      src={info}
                      alt="info-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleInfo();
                      }}
                      className="info-icon"
                    />
                  )} */}
              </div>
            );
          }}
        />
      </div>
      <div className="heatmap-padding-bottom">
        <HeatMap
          xLabels={["", "", "", ""]}
          yLabels={["", ""]}
          xLabelWidth={0}
          yLabelWidth={0}
          data={data5?.map((row) =>
            row.map((cell, index) => {
              return { value: cell.label, id: index };
            })
          )}
          squares={false}
          height={70}
          tooltipDataAttrs={() => ({})} // Disable default title attribute
          title={(value, unit) => ""}
          tooltip={false}
          cellStyle={(background, value, label, min, max, data, x, y) => ({
            background: getColorFromLegend(
              value?.value,
              data5,
              t("heatmap.heatmap_3")
            ),
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "20.8px",
            color: themeColors.background,
            margin: value?.id === 1 ? "1px 10px 0px 0px" : "1px 1px 0px 0px"
          })}
          cellRender={(value, x, y) => {
            const res = getValueFromLabel(data5, value?.value);
            const countOfValues = getCountOfValues(heatmap3Data);
            const checkPercentage = getPercentage(res?.value, countOfValues);
            return (
              <div
                onClick={(e) => {
                  e.stopPropagation(); // Prevent click from propagating to the parent
                  handleOnclick(
                    value?.value,
                    res?.value,
                    t("heatmap.heatmap_3")
                  );
                }}
                className="heatmap-box-cursor"
              >
                <div>{value?.value}</div>
                <span className="heatmap-label-text">
                  {filterType === "Revenue"
                    ? `${convertToK(res?.value)} €`
                    : convertToK(res?.value)}
                  &nbsp;
                  {`(${checkPercentage}%)`}
                </span>
                {/* {Object.keys(selectedFilters)?.length > 0 &&
                  res?.value !== 0 && (
                    <img
                      src={info}
                      alt="info-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleInfo();
                      }}
                      className="info-icon"
                    />
                  )} */}
              </div>
            );
          }}
        />
      </div>
      <Dialog
        open={openModel}
        onClose={() => {
          handleClose();
        }}
        maxWidth="lg"
        fullWidth
        className="heatmap-persona-profile"
      >
        <div>
          <div className="flex-end">
            <img
              src={CloseIcon}
              alt="close-icon"
              onClick={() => handleClose()}
              className="close-icon"
            />
          </div>
          <div>
            <text className="profile-header-text">
              {t("hamburgerHeader.profile")}
            </text>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
